// ModelManagement.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext'; // Import useAuth hook
import './ModelManagement.css'; // Adjust the path as necessary

function ModelManagement() {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useAuth(); // Access token from AuthContext

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

      // A helper function to include the token in API requests
      const fetchWithToken = (url, options = {}) => {
        options.headers = {
          ...options.headers,
          'Authorization': `Bearer ${token}` // Include token in headers
        };
        return fetch(url, options);
      };

  const fetchModels = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchWithToken(`${API_URL}/api/models/list_models`);
      if (!response.ok) {
        throw new Error('Failed to fetch models');
      }
      const data = await response.json();
      setModels(data);
    } catch (error) {
      console.error('Error fetching models:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const refreshModels = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchWithToken(`${API_URL}/api/models/update_models`);
      if (!response.ok) {
        throw new Error('Failed to refresh models');
      }
      await fetchModels();
    } catch (error) {
      console.error('Error refreshing models:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  return (
    <div className="model-management-container">
      <button onClick={refreshModels} disabled={loading} className="refresh-button">
        {loading ? 'Refreshing...' : 'Refresh Models'}
      </button>
      {error && <div className="error-message">Error: {error}</div>}
      <div className="model-table-container">
        <table className="model-table">
          <thead>
            <tr>
              <th>Model Name</th>
              <th>Display Name</th>
              <th>Category</th>
              <th>Embedding Dims</th>
              <th>Context Window</th>
            </tr>
          </thead>
          <tbody>
            {models.map(model => (
              <tr key={model.model_name}>
                <td>{model.model_name}</td>
                <td>{model.display_name}</td>
                <td>{model.model_category}</td>
                <td>{model.embedding_dims || 'N/A'}</td>
                <td>{model.context_window ? `${model.context_window} tokens` : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ModelManagement;
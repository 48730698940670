import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../auth/AuthContext';

import './TenantGroups.css'; // Ensure you have a corresponding CSS file for styling

const TenantGroups = ({ selectedTenant }) => {
  const { token } = useAuth();
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const fetchWithToken = async (url, options = {}) => {
    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error('Fetch with token error:', error);
      throw new Error('Failed to execute fetch with token.');
    }
  };

  const fetchGroupsAndUsers = useCallback(async () => {
    if (!selectedTenant) return;
    try {
      const groupResponse = await fetchWithToken(`${API_URL}/api/auth_config/group_config/groups?tenantId=${selectedTenant}`);
      if (groupResponse.ok) {
        const groupData = await groupResponse.json();
        setGroups(groupData);
      } else {
        throw new Error('Failed to fetch groups');
      }

      const userResponse = await fetchWithToken(`${API_URL}/api/auth_config/user_config/users?tenantId=${selectedTenant}`);
      if (userResponse.ok) {
        const userData = await userResponse.json();
        setUsers(userData);
      } else {
        throw new Error('Failed to fetch users');
      }
    } catch (error) {
      console.error(error);
    }
  }, [selectedTenant, token, API_URL]);

  useEffect(() => {
    fetchGroupsAndUsers();
  }, [fetchGroupsAndUsers]);

  const handleGroupSelection = (group) => {
    setSelectedGroup(group);
    setGroupName(group.groupName);
  };

  const handleAddGroup = async () => {
    if (!newGroupName.trim()) return;
    try {
      const response = await fetchWithToken(`${API_URL}/api/auth_config/group_config/groups/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ groupName: newGroupName, tenantId: selectedTenant }),
      });
      if (response.ok) {
        setNewGroupName('');
        fetchGroupsAndUsers();
      } else {
        throw new Error('Failed to add group');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      const response = await fetchWithToken(`${API_URL}/api/auth_config/group_config/groups/${groupId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchGroupsAndUsers();
        if (selectedGroup && groupId === selectedGroup._id) {
          setSelectedGroup(null); // Reset selected group if it's deleted
        }
      } else {
        throw new Error('Failed to delete group');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveGroup = async () => {
    if (!groupName.trim()) return;
    try {
      const url = selectedGroup ? `${API_URL}/api/auth_config/group_config/groups/${selectedGroup._id}` : `${API_URL}/api/auth_config/group_config/groups/create`;
      const method = selectedGroup ? 'PUT' : 'POST';
      const response = await fetchWithToken(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ groupName, members: selectedGroup ? selectedGroup.members : [], tenantId: selectedTenant }),
      });
      if (response.ok) {
        fetchGroupsAndUsers();
        if (method === 'POST') {
          setGroupName(''); // Clear group name if new group was added
        }
      } else {
        throw new Error(`Failed to ${selectedGroup ? 'update' : 'create'} group`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="group-content">
    <div className="tenantgroup-tenant-groups">
      <h2>Groups in Tenant: {selectedTenant}</h2>
      <div className="tenantgroup-group-creation">
        <input
          type="text"
          placeholder="New Group Name"
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
          className="tenantgroup-input"
        />
        <button onClick={handleAddGroup} className="tenantgroup-add-btn">Add Group</button>
      </div>
      <ul className="tenantgroup-group-list">
        {groups.map((group) => (
          <li key={group._id} 
              className={`tenantgroup-group-item ${selectedGroup && selectedGroup._id === group._id ? 'tenantgroup-selected' : ''}`} 
              onClick={() => handleGroupSelection(group)}>
            {group.groupName}
            <button onClick={() => handleDeleteGroup(group._id)} className="tenantgroup-delete-btn">Delete</button>
          </li>
        ))}
      </ul>
      {selectedGroup && (
        <div className="tenantgroup-group-details">
          <input
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Edit Group Name"
            className="tenantgroup-input"
          />
          <button onClick={saveGroup} className="tenantgroup-save-btn">{selectedGroup ? 'Update Group' : 'Create Group'}</button>
        </div>
      )}
    </div>
    </div>
  );
};

export default TenantGroups;
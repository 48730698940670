import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import './BotManagement.css';

const BotManagement = () => {
  const { token } = useAuth();
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState(null);
  const [newBotName, setNewBotName] = useState('');
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  // Define fetchBots outside useEffect so it can be reused
  const fetchBots = async () => {
    const response = await fetch(`${API_URL}/api/config/bot-collections`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    setBots(data);
  };

  useEffect(() => {
    fetchBots(); // Call fetchBots within useEffect
  }, [API_URL, token]); // Dependencies

  const handleSelectBot = (bot) => {
    setSelectedBot(bot);
  };

  const handleAddBot = async () => {
    await fetch(`${API_URL}/api/config/bot-collections`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: newBotName })
    });

    setNewBotName('');
    await fetchBots(); // Call fetchBots to refresh the list of bots
  };

  const handleDeleteBot = async (botId) => {
    await fetch(`${API_URL}/api/config/bot-collections/${botId}`, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` }
    });

    await fetchBots(); // Call fetchBots to refresh the list of bots
  };

  // Sidebar for bot selection
  const BotSidebar = () => (
    <aside className="bot-sidebar">
      <h2>Bots</h2>
      <div className="bot-navigation">
        {bots.map((bot) => (
          <button key={bot._id} onClick={() => handleSelectBot(bot)} className="bot-button">
            {bot.name}
          </button>
        ))}

      </div>
    </aside>
  );

  // Main content area for selected bot details and actions
  const BotContent = () => (
    <section className="bot-content">
              <div className="add-bot-form">
          <input
            type="text"
            value={newBotName}
            onChange={(e) => setNewBotName(e.target.value)}
            placeholder="Enter new bot name"
          />
          <button onClick={handleAddBot}>Add Bot</button>
        </div>
      {selectedBot && (
        <div className="bot-details">
          <h3>{selectedBot.name}</h3>
          <p>Created: {new Date(selectedBot.createdAt).toLocaleString()}</p>
          <p>Updated: {new Date(selectedBot.updatedAt).toLocaleString()}</p>
          <button onClick={() => handleDeleteBot(selectedBot._id)}>Delete Bot</button>
        </div>
      )}
    </section>
  );

  return (
    <div className="bot-management-container">
      <BotSidebar />
      <BotContent />
    </div>
  );
};

export default BotManagement;

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../auth/AuthContext';
import './UserGroupManagement.css';

const UserGroupManagement = () => {
  const { token } = useAuth();
  const [tenants, setTenants] = useState([]);
  const [newTenantName, setNewTenantName] = useState('');
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState(new Set());
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';



  const fetchTenants = useCallback(async () => {
    try {
      const response = await fetchWithToken(`${API_URL}/api/auth_config/tenant_config/tenants`);
      if (response.ok) {
        const data = await response.json();
        setTenants(data);
      } else {
        console.error('Failed to fetch tenants:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching tenants:', error);
    }
  }, [token, API_URL]);

  const createTenant = useCallback(async () => {
    try {
      const response = await fetchWithToken(`${API_URL}/api/auth_config/tenant_config/tenants/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tenantName: newTenantName }),
      });
      if (response.ok) {
        setNewTenantName('');
        fetchTenants();
      } else {
        console.error('Failed to create tenant:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating tenant:', error);
    }
  }, [newTenantName, token, API_URL, fetchTenants]);

  const fetchGroupsAndUsers = useCallback(async () => {
    if (!selectedTenant) return;
    const groupResponse = await fetchWithToken(`${API_URL}/api/auth_config/group_config/groups?tenantId=${selectedTenant}`);
    if (!groupResponse.ok) throw new Error('Failed to fetch groups');
    const groupData = await groupResponse.json();
    setGroups(groupData);

    const userResponse = await fetchWithToken(`${API_URL}/api/auth_config/user_config/users?tenantId=${selectedTenant}`);
    if (!userResponse.ok) throw new Error('Failed to fetch users');
    const userData = await userResponse.json();
    setUsers(userData);
  }, [selectedTenant, token, API_URL]);
  

  const fetchWithToken = async (url, options = {}) => {
    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error('Fetch with token error:', error);
      throw new Error('Failed to execute fetch with token.');
    }
  };

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);

  useEffect(() => {
    if (selectedTenant) fetchGroupsAndUsers();
  }, [selectedTenant, fetchGroupsAndUsers]);

  const handleGroupSelection = group => {
    setSelectedGroup(group);
    setGroupName(group.groupName);
    setSelectedUserIds(new Set(group.members));
  };

  const saveGroup = async () => {
    const memberIds = Array.from(selectedUserIds);
    const url = selectedGroup ? `${API_URL}/api/auth_config/group_config/groups/${selectedGroup._id}` : `${API_URL}/api/auth_config/group_config/groups/create`;
    const method = selectedGroup ? 'PUT' : 'POST';
    await fetchWithToken(url, {
      method, headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ groupName, members: memberIds, tenantId: selectedTenant }),
    });
    fetchGroupsAndUsers();
  };

  const deleteGroup = async groupId => {
    await fetchWithToken(`${API_URL}/api/auth_config/group_config/groups/${groupId}`, { method: 'DELETE' });
    fetchGroupsAndUsers();
  };

  const toggleUserSelection = userId => {
    const updatedSelection = new Set(selectedUserIds);
    updatedSelection.has(userId) ? updatedSelection.delete(userId) : updatedSelection.add(userId);
    setSelectedUserIds(updatedSelection);
  };

  const resetGroupForm = () => {
    setSelectedGroup(null);
    setGroupName('');
    setSelectedUserIds(new Set());
  };


  const GroupContent = ({ groupName, handleGroupNameChange, selectedUserIds, users, toggleUserSelection, selectAllUsers, deselectAllUsers, saveGroup, deleteGroup, selectedGroup, resetGroupForm }) => (
    <section className="group-content">
      <div className="group-details">
        <div className="form-field">
          <label htmlFor="group-name">Group Name</label>
          <input
            id="group-name"
            value={groupName}
            onChange={handleGroupNameChange}
            placeholder="Enter group name"
          />
        </div>
        <UserList users={users} selectedUserIds={selectedUserIds} toggleUserSelection={toggleUserSelection} selectAllUsers={selectAllUsers} deselectAllUsers={deselectAllUsers} />
        <div className="group-actions">
          <button onClick={saveGroup}>{selectedGroup ? 'Update Group' : 'Create Group'}</button>
          {selectedGroup && <button className="delete-btn" onClick={() => deleteGroup(selectedGroup._id)}>Delete Group</button>}
          <button onClick={resetGroupForm} style={{ marginLeft: '10px' }}>New Group</button> {/* Add New Group button */}
        </div>
      </div>
    </section>
  );
  
  const UserList = ({ users, selectedUserIds, toggleUserSelection, selectAllUsers, deselectAllUsers }) => (
    <div className="user-list">
      <div className="list-actions">
        <button onClick={selectAllUsers}>Select All</button>
        <button onClick={deselectAllUsers}>Deselect All</button>
      </div>
      <div className="users">
        {users.map(user => (
          <label key={user._id} className="user-item">
            <input
              type="checkbox"
              checked={selectedUserIds.has(user._id)}
              onChange={() => toggleUserSelection(user._id)}
            />
            {user.username}
          </label>
        ))}
      </div>
    </div>
  );

  return (
    <div className="user-group-management-container">
      <Sidebar {...{ tenants, selectedTenant, groups, selectedGroup, onTenantClick: setSelectedTenant, onGroupSelection: handleGroupSelection, newTenantName, setNewTenantName, createTenant }} />
      {selectedTenant && <GroupContent {...{ groupName, handleGroupNameChange: (e) => setGroupName(e.target.value), selectedUserIds, users, toggleUserSelection, selectAllUsers: () => setSelectedUserIds(new Set(users.map(u => u._id))), deselectAllUsers: () => setSelectedUserIds(new Set()), saveGroup, deleteGroup, selectedGroup, resetGroupForm }} />}
    </div>
  );
};

const Sidebar = ({
  tenants,
  selectedTenant,
  groups,
  selectedGroup,
  onTenantClick,
  onGroupSelection,
  newTenantName,
  setNewTenantName,
  createTenant
}) => (
  <aside className="sidebar">
    <div className="tenant-creation">
      <input
        type="text"
        placeholder="New Tenant Name"
        value={newTenantName}
        onChange={e => setNewTenantName(e.target.value)}
      />
      <button onClick={createTenant}>Create Tenant</button>
    </div>
    <div className="tenant-list">
      {tenants.map(tenant => (
        <div
          key={tenant._id}
          className={`tenant-item ${selectedTenant === tenant._id ? 'selected' : ''}`}
          onClick={() => onTenantClick(tenant._id)}
        >
          {tenant.tenantName}
          {selectedTenant === tenant._id && (
            <div className="group-list">
              {groups.map(group => (
                <div
                  key={group._id}
                  className={`group-item ${selectedGroup && selectedGroup._id === group._id ? 'selected' : ''}`}
                  onClick={() => onGroupSelection(group)}
                >
                  {group.groupName}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  </aside>
);

export default UserGroupManagement;

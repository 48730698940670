// DropboxAuth.js

import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

const DropboxAuth = ({ onAuthSuccess }) => {
    const { user } = useAuth();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    useEffect(() => {
        if (user && user.userId) {
            const dropboxAuthUrl = `${API_URL}/api/dropbox/auth?userId=${user.userId}`;
            const authWindow = window.open(dropboxAuthUrl, '_blank', 'width=800,height=600');

            const receiveMessage = (event) => {
                if (event.data === 'dropbox-auth-success') {
                    console.log('Dropbox authentication successful.');
                    onAuthSuccess(); // Call the callback function passed as a prop
                }
            };

            window.addEventListener('message', receiveMessage);

            return () => {
                window.removeEventListener('message', receiveMessage);
                if (authWindow) authWindow.close();
            };
        }
    }, [user, API_URL, onAuthSuccess]);

    return <div>Redirecting to Dropbox...</div>;
};

export default DropboxAuth;

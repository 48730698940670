import React, { useState, useEffect } from 'react';
import { useAuth } from './auth/AuthContext'; // Import useAuth hook
import './AdvancedLandingPage.css';

function AdvancedLandingPage({ onSelectBot, onRagQueryClick }) {
  const [bots, setBots] = useState([]);
  const { token } = useAuth(); // Access token from AuthContext
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  // A helper function to include the token in API requests
  const fetchWithToken = (url, options = {}) => {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}` // Include token in headers
    };
    return fetch(url, options);
  };

  useEffect(() => {
    // Fetch the list of bots
    fetchWithToken(`${API_URL}/api/config/bot-collections`)
      .then(response => response.json())
      .then(data => setBots(data))
      .catch(error => console.error('Error fetching bots:', error));
  }, [API_URL, token]);

  return (
    <div className="landing-page">
      <div className="bot-selection">
        {bots.map(bot => (
          // Update the onClick handler to include both libraryMappings and modelMappings
          <div key={bot._id} className="bot-tile" onClick={() => onSelectBot(bot.name, bot.libraryMappings, bot.modelMappings)}>
            <h2>{bot.name}</h2>
          </div>
        ))}
        <div className="bot-tile" onClick={onRagQueryClick}>
          <h2>Start RAG Query Chat</h2>
        </div>
      </div>
    </div>
  );
}

export default AdvancedLandingPage;

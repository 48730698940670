import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useAuth } from './auth/AuthContext'; // Import useAuth hook

const SinglePromptQuery = ({ selectedBot }) => {
  const { token } = useAuth(); // Access token from AuthContext
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [useLLMAI, setUseLLMAI] = useState(false); // State to track API selection
  const singlePromptQueryEndRef = useRef(null);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const [availableFiles, setAvailableFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [query, setQuery] = useState('');
  const [modelName, setModelName] = useState("gpt-4-1106-preview"); // State for selected model

  // Array of available models
  const availableModels = ["gpt-3.5", "gpt-4", "gpt-4-1106-preview"];

    // A helper function to include the token in API requests
    const fetchWithToken = (url, options = {}) => {
      options.headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}` // Include token in headers
      };
      return fetch(url, options);
    };

    useEffect(() => {
      // Use fetchWithToken for secure API requests
      fetchWithToken(`${API_URL}/api/list-files`)
        .then(response => response.json())
        .then(data => setAvailableFiles(data.files))
        .catch(error => console.error('Error fetching files:', error));
    }, [API_URL, token]); // Adding token as a dependency
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!input.trim() || !query.trim()) return;
  
      const userMessage = { role: 'user', content: input };
      setMessages(messages => [...messages, userMessage]);
  
      try {
        const requestBody = {
          model_name: modelName,
          file_name: selectedFile,
          prompt: input,
          query: query 
        };
  
        const result = await fetchWithToken(`${API_URL}/api/singlePromptQuery`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        });
  
        if (!result.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseText = await result.text();
        const aiMessage = { role: 'ai', content: responseText };
        setMessages(messages => [...messages, aiMessage]);
  
      } catch (error) {
        console.error('Fetch error:', error);
      }
  
      setInput('');
    };

  const renderModelSelection = () => (
    <select
      disabled={useLLMAI}
      value={modelName}
      onChange={(e) => setModelName(e.target.value)}
    >
      {availableModels.map((model, index) => (
        <option key={index} value={model}>
          {model}
        </option>
      ))}
    </select>
  );

  return (
    <>
      <h1>{selectedBot} Single Prompt Query</h1>
      <div className="toggle-api">
        <label>
          <input
            type="checkbox"
            checked={useLLMAI}
            onChange={(e) => {
              setUseLLMAI(e.target.checked);
              if (e.target.checked) {
                setModelName("llmware/bling-1b-0.1"); // Default to local LLM model
              } else {
                setModelName("gpt-4-1106-preview"); // Reset to GPT-4 or any default model
              }
            }}
          />
          Use Local LLM
        </label>
        {!useLLMAI && renderModelSelection()}
      </div>
      <div className="singlePromptQuery-window">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            <ReactMarkdown className="markdown-content">{msg.content}</ReactMarkdown>
          </div>
        ))}
        <div ref={singlePromptQueryEndRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message here..."
        />
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query..."
        />
        <button type="submit">Send</button>
      </form>
      <div className="file-selection">
        <label htmlFor="file-select">Choose a file:</label>
        <select
          id="file-select"
          value={selectedFile}
          onChange={(e) => setSelectedFile(e.target.value)}
        >
          <option value="" disabled>
            Please select a file
          </option>
          {availableFiles.map(file => (
            <option key={file} value={file}>{file}</option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SinglePromptQuery;

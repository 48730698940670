import React, { useState } from 'react';
import { AuthProvider } from './auth/AuthContext';
import { jwtDecode } from 'jwt-decode';
import AdvancedLandingPage from './AdvancedLandingPage';
import AdminPage from './management/AdminPage';
import LoginAndRegister from './auth/LoginAndRegister';
import SinglePromptQuery from './SinglePromptQuery';
import RagQueryChat from './chat/RagQueryChat';
import RagBotQueryChat from './chat/RagBotQueryChat';
import SearchAndChat from './search/SearchAndChat';
import DropboxAuth from './auth/DropboxAuth';
import './App.css';

function App() {
  const [selectedBot, setSelectedBot] = useState('');
  const [isAdminView, setIsAdminView] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isRagQueryView, setIsRagQueryView] = useState(false);
  const [isRagBotQueryView, setIsRagBotQueryView] = useState(false);
  const [selectedBotLibraries, setSelectedBotLibraries] = useState([]);
  const [isSimpleSearch, setIsSimpleSearch] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [selectedBotModels, setSelectedBotModels] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropboxAuth, setShowDropboxAuth] = useState(false);

  const handleDropboxAuthSuccess = () => {
    setShowDropboxAuth(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(prevShow => !prevShow);
  };

  const handleAdvancedSearchClick = () => {
    setIsAdvancedSearch(true);
    setIsAdminView(false);
    setIsRagQueryView(false);
    setIsRagBotQueryView(false);
    // Ensure other views are reset to false to maintain correct application state
  };

  const handleRagQueryClick = () => {
    setIsRagQueryView(true);
    setIsAdminView(false);
    // Reset other related views to ensure correct application state
  };

  const handleLoginSuccess = (token) => {
    console.log("Received token:", token);
    try {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
      setUserRole(userRole);
      setIsAuthenticated(true);
      setIsAdminView(userRole === 'admin');
      setIsSimpleSearch(true); // Ensure the view is correctly set upon login
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setIsAdminView(false);
    setIsSimpleSearch(false);
    // Reset all states related to the user session and views
  };

  const handleSelectBot = (botName, libraries, models) => {
    setSelectedBot(botName);
    setSelectedBotLibraries(libraries);
    setSelectedBotModels(models);
    setIsRagBotQueryView(true);
    // Ensure other views are correctly reset
    setIsAdminView(false);
    setIsRagQueryView(false);
    setIsAdvancedSearch(false); // Reset this to ensure consistent application state
  };

  const handleBackToLanding = () => {
    setIsAdminView(false);
    setSelectedBot('');
    setIsRagQueryView(false);
    setIsRagBotQueryView(false);
    setIsAdvancedSearch(false);
    setIsSimpleSearch(true); // Ensure the default view is set back to simple search
  };

  const renderHeaderButtons = () => (
    <div className="header-container">
      <div className="header-left">
        <button className="header-button" onClick={handleBackToLanding}>Home</button>
        <button className="header-button" onClick={handleAdvancedSearchClick}>Advanced Search</button>
      </div>
      <div className="header-right">
        {isAuthenticated && (
          <div className={`dropdown ${showDropdown ? 'open' : ''}`}>
            <button className="header-button" onClick={toggleDropdown}>Menu</button>
            <div className="dropdown-content">
              {isAuthenticated && userRole === 'admin' && !isAdminView && (
                <button className="header-button" onClick={() => setIsAdminView(true)}>Admin</button>
              )}
              <button onClick={() => setShowDropboxAuth(true)}>Dropbox Auth</button>
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const renderFooter = () => (
    <footer className="App-footer">
      <p>© {new Date().getFullYear()} MyDocSearch</p>
      <p>All rights reserved.</p>
    </footer>
  );

  return (
    <AuthProvider>
      <div className="App">
        <header className="App-header">
          {renderHeaderButtons()}
        </header>

        {showDropboxAuth ? (
          <DropboxAuth onAuthSuccess={handleDropboxAuthSuccess} />
        ) : !isAuthenticated ? (
          <LoginAndRegister onLoginSuccess={handleLoginSuccess} />
        ) : renderContentBasedOnState()}

        {renderFooter()}
      </div>
    </AuthProvider>
  );

  function renderContentBasedOnState() {
    // This function handles conditional rendering based on the application's state
    if (isAdminView && userRole === 'admin') {
      return <AdminPage onBackClick={handleBackToLanding} />;
    } else if (isRagQueryView) {
      return <RagQueryChat selectedBot={selectedBot} />;
    } else if (isRagBotQueryView) {
      return <RagBotQueryChat selectedBot={selectedBot} botLibraries={selectedBotLibraries} botModels={selectedBotModels} />;
    } else if (isAdvancedSearch) {
      return <AdvancedLandingPage onSelectBot={handleSelectBot} onRagQueryClick={handleRagQueryClick} />;
    } else if (isSimpleSearch) {
      return <SearchAndChat selectedBot={selectedBot} />;
    } else if (selectedBot) {
      return <SinglePromptQuery selectedBot={selectedBot} />;
    }
    return null;
  }
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../auth/AuthContext';
import './SearchAndChat.css';
// Import the SVG icon files
import historyIcon from '../images/history-icon.png'; // Adjust the path according to your file structure
import sortIcon from '../images/sort-icon.png'; // Adjust the path accordingly
import toggleChatModeIcon from '../images/toggle-chat-mode-icon.png';


// Define MAX_PREVIOUS_SEARCHES
const MAX_PREVIOUS_SEARCHES = 10; // Adjust this value as needed

function SearchAndChat() {
    // --- State Declarations ---

    // UI States
    const [isChatMode, setIsChatMode] = useState(true);
    const [isOnline, setIsOnline] = useState(false);
    const [isLeftExpanded, setIsLeftExpanded] = useState(false);
    const [isRightExpanded, setIsRightExpanded] = useState(false);
    const [showPreviousSearches, setShowPreviousSearches] = useState(false);
    const [showChatListPanel, setShowChatListPanel] = useState(false);

    // Search States
    const [libraries, setLibraries] = useState([]);
    const [selectedLibraries, setSelectedLibraries] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [messages, setMessages] = useState([]);
    const [sessionId, setSessionId] = useState(null);
    const [expandedTextData, setExpandedTextData] = useState({});

    // Filter States
    const [docIdFilter, setDocIdFilter] = useState('');
    const [blockIdFilter, setBlockIdFilter] = useState('');
    const [pageNumFilter, setPageNumFilter] = useState('');
    const [textSnippetFilter, setTextSnippetFilter] = useState('');
    const [fileSourceFilter, setFileSourceFilter] = useState('');
    const [fileTypeFilter, setFileTypeFilter] = useState('');
    const [authorOrSpeakerFilter, setAuthorOrSpeakerFilter] = useState('');
    const [addedToCollectionFilter, setAddedToCollectionFilter] = useState('');
    const [modifiedDateFilter, setModifiedDateFilter] = useState('');
    const [createdDateFilter, setCreatedDateFilter] = useState('');
    const [sortCriteria, setSortCriteria] = useState('match_status');
    const [sortOrder, setSortOrder] = useState('asc');
    const [activeFilter, setActiveFilter] = useState(null);
    const [activeFilters, setActiveFilters] = useState([]);

    // Chat States
    const [chatList, setChatList] = useState([]);
    const [shouldSubmitQuery, setShouldSubmitQuery] = useState(false);
    const [isAutoChatEnabled, setIsAutoChatEnabled] = useState(true);

    // iFrame State
    const [iframeSrc, setIframeSrc] = useState(null);

    // Previous Searches State
    const PREVIOUS_SEARCHES_STORAGE_KEY = 'previousSearches';
    const [previousSearches, setPreviousSearches] = useState(() => {
        const storedSearches = localStorage.getItem(PREVIOUS_SEARCHES_STORAGE_KEY);
        return storedSearches ? JSON.parse(storedSearches) : [];
    });




    // --- API URL and Auth Context ---
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
    const { token } = useAuth();

    // --- Utility and Helper Functions ---

    const fetchWithToken = (url, options = {}) => {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}`
        };
        return fetch(url, options);
    };

    const handleExpandText = async (result, direction) => {
        const requestBody = {
            libraryName: result.library_name,
            docId: result.doc_id,
            blockId: result.block_id,
            expandBefore: direction === 'both' || direction === 'before',
            expandAfter: direction === 'both' || direction === 'after',
        };

        try {
            const response = await fetchWithToken(`${API_URL}/api/search/expand_text_around_block`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) throw new Error('Failed to expand text');

            const data = await response.json();
            setExpandedTextData(prevData => ({
                ...prevData,
                [result.block_id]: { ...prevData[result.block_id], ...data }
            }));
        } catch (error) {
            console.error('Error expanding text:', error);
        }
    };


    // --- Toggle Functions ---

    const toggleChatMode = () => setIsChatMode(!isChatMode);
    const toggleOnlineStatus = () => {
        setIsOnline(!isOnline);
    };
    const toggleLeftExpansion = () => {
        setIsLeftExpanded(!isLeftExpanded);
        if (isRightExpanded) setIsRightExpanded(false);
    };
    const toggleRightExpansion = () => {
        setIsRightExpanded(!isRightExpanded);
        if (isLeftExpanded) setIsLeftExpanded(false);
    };
    const toggleChatListPanel = () => setShowChatListPanel(!showChatListPanel);
    const removeFromChatList = (itemToRemove) => setChatList(chatList.filter(item => item !== itemToRemove));

    // --- Sorting and Result Handling ---

    const sortGroupResults = (results) => {
        return results.sort((a, b) => {
            if (sortCriteria === 'match_status') {
                return sortOrder === 'asc' ? a.data.match_status.localeCompare(b.data.match_status) : b.data.match_status.localeCompare(a.data.match_status);
            } else if (sortCriteria === 'library_name') {
                return sortOrder === 'asc' ? a.data.library_name.localeCompare(b.data.library_name) : b.data.library_name.localeCompare(a.data.library_name);
            }
        });
    };

    // Effect for dynamic sorting
    useEffect(() => {
        // Function to group messages by query-result pairs
        const groupMessagesByQuery = () => {
            const groups = [];
            let currentGroup = [];

            messages.forEach((msg) => {
                if (msg.type === 'query') {
                    if (currentGroup.length > 0) {
                        groups.push(currentGroup);
                    }
                    currentGroup = [msg];
                } else {
                    currentGroup.push(msg);
                }
            });

            if (currentGroup.length > 0) {
                groups.push(currentGroup);
            }

            return groups;
        };

        // Group messages by query-result pairs
        const groupedMessages = groupMessagesByQuery();

        // Sort each group of results and reconstruct the messages array
        const updatedMessages = groupedMessages.flatMap(group => {
            const queryMessage = group[0];
            const resultMessages = group.slice(1);
            const sortedResults = sortGroupResults(resultMessages);
            return [queryMessage, ...sortedResults];
        });

        setMessages(updatedMessages);
    }, [sortCriteria, sortOrder]);


    // --- Local Storage Management ---

    useEffect(() => {
        const recentSearches = previousSearches.slice(0, MAX_PREVIOUS_SEARCHES);
        localStorage.setItem(PREVIOUS_SEARCHES_STORAGE_KEY, JSON.stringify(recentSearches));
    }, [previousSearches]);

    // --- Library Data Fetching ---

    useEffect(() => {
        const fetchLibraries = async () => {
            try {
                const response = await fetchWithToken(`${API_URL}/api/libraries/load-libraries`);
                const data = await response.json();
                setLibraries(data);
                setSelectedLibraries(data.map(lib => lib.name));
            } catch (error) {
                console.error('Error fetching libraries:', error);
            }
        };

        fetchLibraries();
    }, [API_URL, token]);

    // --- Chat List Management ---

    const handleResultSelect = (result, searchId) => {
        setChatList(prevChatList => {
            const index = prevChatList.findIndex(item => item.doc_id === result.doc_id && item.page_num === result.page_num && item.block_id === result.block_id);
            if (index > -1) return prevChatList.filter((_, i) => i !== index);

            const newItem = { ...result, searchId };
            const itemsToAdd = [expandedTextData[result.block_id]?.expanded_text_before ? { ...newItem, block_id: result.block_id - 1, text_snippet: expandedTextData[result.block_id].expanded_text_before } : null, newItem, expandedTextData[result.block_id]?.expanded_text_after ? { ...newItem, block_id: result.block_id + 1, text_snippet: expandedTextData[result.block_id].expanded_text_after } : null].filter(Boolean);

            return [...prevChatList, ...itemsToAdd];
        });
    };


    const handleAddTopMatches = () => {
        messages.filter(msg => msg.role === 'ai').slice(0, 5).forEach(result => handleResultSelect(result.data, result.searchId));
    };

    const handleExpandAndAddTopResults = async () => {
        for (const result of messages.filter(msg => msg.role === 'ai').slice(0, 3)) {
            await handleExpandText(result.data, 'both');
            handleResultSelect(result.data, result.searchId);
        }
    };


    // --- Utility Functions ---

    const getFileName = (filePath) => {
        return filePath.split('\\').pop().split('/').pop();
    };

    const formatDateForInput = (rawDate) => {
        if (!rawDate) return '';

        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateRegex.test(rawDate)) return rawDate;

        const longDateRegex = /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{2}:\d{2}:\d{2} \d{4}$/;
        if (longDateRegex.test(rawDate)) {
            const dateObj = new Date(rawDate);
            return dateObj.toISOString().split('T')[0];
        }

        const [date, time] = rawDate.split(' ');
        if (date.includes('/')) {
            const [month, day, year] = date.split('/');
            return `20${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }

        return '';
    };


    // --- Search Functionality ---

    const createCustomFilterObject = () => {
        const filters = [];
        if (docIdFilter) filters.push({ doc_id: docIdFilter });
        if (blockIdFilter) filters.push({ block_id: blockIdFilter });
        if (pageNumFilter) filters.push({ master_index: pageNumFilter });
        if (textSnippetFilter) filters.push({ text: textSnippetFilter });
        if (fileSourceFilter) filters.push({ file_source: fileSourceFilter });
        if (fileTypeFilter) filters.push({ file_type: fileTypeFilter });
        if (authorOrSpeakerFilter) filters.push({ author_or_speaker: authorOrSpeakerFilter });
        if (addedToCollectionFilter) filters.push({ added_to_collection: addedToCollectionFilter });
        if (modifiedDateFilter) filters.push({ modified_date: modifiedDateFilter });
        if (createdDateFilter) filters.push({ created_date: createdDateFilter });
        return filters;
    };

    const getNewChatListItems = (result, searchId) => {
        const newItem = { ...result, searchId };
        return [
            expandedTextData[result.block_id]?.expanded_text_before ? { ...newItem, block_id: result.block_id - 1, text_snippet: expandedTextData[result.block_id].expanded_text_before } : null,
            newItem,
            expandedTextData[result.block_id]?.expanded_text_after ? { ...newItem, block_id: result.block_id + 1, text_snippet: expandedTextData[result.block_id].expanded_text_after } : null
        ].filter(Boolean);
    };

    useEffect(() => {
        if (shouldSubmitQuery && isAutoChatEnabled) {
            handleSubmit(null, searchQuery).then(() => {
                setShouldSubmitQuery(false);  // Reset the flag after submission
            });
        }
    }, [shouldSubmitQuery, isAutoChatEnabled, searchQuery, chatList]);  // Include chatList in dependencies to ensure useEffect reacts to its updates


    const handleSearch = async (event) => {
        if (event) event.preventDefault();
        if (!searchQuery.trim()) return;

        setIsProcessingSearch(true);

        // Clear existing highlight
        setHighlightedResultIndex(null);  // Assuming this is the state used to track highlighted result


        try {
            const requestBody = {
                library_names: selectedLibraries,
                search_query: searchQuery,
                query_text: searchQuery,
                session_id: sessionId,
                custom_filters: createCustomFilterObject(),
            };

            const response = await fetchWithToken(`${API_URL}/api/search/retrieve_semantic_context_with_session`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const responseData = await response.json();
            setSessionId(responseData.session_id);

            const sortedNewResults = sortGroupResults(responseData.results.map(result => ({
                role: 'ai',
                data: result,
                expanded: false,
            })));
            const searchId = Date.now(); // Unique ID for each search
            // Update the messages with ONLY the new results
            const newMessages = [
                { type: 'query', content: searchQuery, searchId: searchId },
                ...sortedNewResults.map(result => ({ ...result, searchId: searchId }))
            ];
            setMessages(newMessages);

            setPreviousSearches(prevSearches => [{ query: searchQuery, results: sortedNewResults }, ...prevSearches].slice(0, MAX_PREVIOUS_SEARCHES));

            if (isAutoChatEnabled) {
                if (!isChatMode) setIsChatMode(true);
                setChatList([]); // Clear the chat list

                const newChatListItems = sortedNewResults.slice(0, 5).flatMap(result => getNewChatListItems(result.data, result.searchId));

                // Update chat list in one go
                setChatList(prevChatList => [...prevChatList, ...newChatListItems]);

                // Set a flag to indicate that handleSubmit should be called once the chat list is updated
                setShouldSubmitQuery(true);  // Assuming setShouldSubmitQuery is a state setter for a new state variable shouldSubmitQuery
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setIsProcessingSearch(false);
        }
    };







    const onSearchClick = (searchQuery) => {
        const previousSearch = previousSearches.find(search => search.query === searchQuery);
        if (previousSearch) {
            setMessages(previousSearch.results);
        } else {
            console.error('Error: Search query not found in previous searches');
            // Optionally, display an error message to the user
        }
    };

    // --- Library Management ---

    const handleToggleLibrary = (libraryName) => {
        setSelectedLibraries(prev => prev.includes(libraryName) ? prev.filter(lib => lib !== libraryName) : [...prev, libraryName]);
    };

    const selectAllLibraries = () => {
        setSelectedLibraries(libraries.map(lib => lib.name));
    };

    const unselectAllLibraries = () => {
        setSelectedLibraries([]);
    };

    // --- Filter Management ---

    const handleFieldSelect = (event, fieldName, result) => {
        event.stopPropagation();

        const filter = { name: fieldName, value: result[fieldName] };
        setActiveFilters(currentFilters => {
            const isCurrentlyActive = currentFilters.some(f => f.name === filter.name && f.value === filter.value);
            if (isCurrentlyActive) {
                return currentFilters.filter(f => f.name !== filter.name || f.value !== filter.value);
            } else {
                return [...currentFilters, filter];
            }
        });

        let value;
        switch (fieldName) {
            case 'page_num':
                value = result.page_num.toString();
                setPageNumFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'text_snippet':
                value = result.text_snippet;
                setTextSnippetFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'doc_id':
                value = result.doc_id.toString();
                setDocIdFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'file_source':
                value = result.file_source;
                setFileSourceFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'file_type':
                value = result.file_type;
                setFileTypeFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'author_or_speaker':
                value = result.author_or_speaker;
                setAuthorOrSpeakerFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'library_name':
                value = result.library_name;
                setSelectedLibraries(prevLibraries => prevLibraries.includes(value) ? prevLibraries.filter(lib => lib !== value) : [...prevLibraries, value]);
                break;
            case 'added_to_collection':
                value = result.added_to_collection;
                setAddedToCollectionFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'modified_date':
                value = result.modified_date;
                setModifiedDateFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'created_date':
                value = result.created_date;
                setCreatedDateFilter(prevValue => prevValue === value ? '' : value);
                break;
            case 'added_to_collection':
            case 'modified_date':
            case 'created_date':
                value = formatDateForInput(result[fieldName]);
                const filterSetter = fieldName === 'added_to_collection' ? setAddedToCollectionFilter
                    : fieldName === 'modified_date' ? setModifiedDateFilter
                        : setCreatedDateFilter;
                filterSetter(prevValue => prevValue === value ? '' : value);
                break;

            default:
                break;
        }
    };

    // --- ResultCard Component ---

    function ResultCard({ result, highlighted, handleResultSelect, handleFieldSelect, handleExpandText, expandedTextData, chatList, docIdFilter, pageNumFilter, textSnippetFilter, fileSourceFilter, fileTypeFilter, authorOrSpeakerFilter, libraryNameFilter, addedToCollectionFilter, modifiedDateFilter, createdDateFilter }) {
        const [isExpanded, setIsExpanded] = useState(false);
        const isResultSelected = chatList.some(item =>
            item.doc_id === result.doc_id &&
            item.page_num === result.page_num &&
            item.block_id === result.block_id);

        // Retrieve expanded text data for the current result
        const currentExpandedData = expandedTextData[result.block_id] || {};


        // Helper function to check if the filter is active
        const isFilterActive = (filterName) => {
            switch (filterName) {
                case 'doc_id':
                    return docIdFilter === result.doc_id.toString();
                case 'page_num':
                    return pageNumFilter === result.page_num.toString();
                case 'text_snippet':
                    return textSnippetFilter === result.text_snippet;
                case 'file_source':
                    return fileSourceFilter === result.file_source;
                case 'file_type':
                    return fileTypeFilter === result.file_type;
                case 'author_or_speaker':
                    return authorOrSpeakerFilter === result.author_or_speaker;
                case 'library_name':
                    return libraryNameFilter.includes(result.library_name);
                case 'added_to_collection':
                    return addedToCollectionFilter === formatDateForInput(result.added_to_collection);
                case 'modified_date':
                    return modifiedDateFilter === formatDateForInput(result.modified_date);
                case 'created_date':
                    return createdDateFilter === formatDateForInput(result.created_date);
                default:
                    return false;
            }
        };

        // Function to handle the "View Document" link click event
        // Function to handle the "View Document" link click event
        const handleViewDocumentClick = async (event, fileUrl, pageNum) => {
            event.preventDefault(); // Prevent default link behavior

            try {
                const fullUrl = `${API_URL}${fileUrl}`;
                const response = await fetchWithToken(fullUrl, { method: 'GET' });
                if (!response.ok) {
                    throw new Error('File access failed');
                }

                const contentType = response.headers.get('content-type');
                if (contentType.includes('pdf')) {
                    // Handle PDF files
                    const fileBlob = await response.blob();
                    const fileObjectURL = URL.createObjectURL(fileBlob);

                    // Update the iframe's src attribute with the PDF URL
                    setIframeSrc(`${fileObjectURL}#page=${pageNum}`);

                    // Switch to document view mode if in chat mode
                    if (isChatMode) {
                        setIsChatMode(false);
                    }
                } else {
                    // Handle other file types
                    console.error('Unsupported file type. Cannot display in iframe.');
                }
            } catch (error) {
                console.error('Error during file access:', error);
            }
        };

        const [analysisResults, setAnalysisResults] = useState(null);
        const [isAnalyzing, setIsAnalyzing] = useState(false);
        const [analysisError, setAnalysisError] = useState(null);
    
        const handleAnalyzeResult = async () => {
            setIsAnalyzing(true);
            setAnalysisResults(null); // Clear previous results
            setAnalysisError(null); // Clear previous errors
        
            const requestBody = {
                libraryName: result.library_name,
                blockId: result.block_id,
                docId: result.doc_id,
            };
        
            try {
                const response = await fetchWithToken(`${API_URL}/api/analyze/analyze_text_block`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestBody),
                });
        
                if (!response.ok) throw new Error('Failed to analyze');
        
                const responseData = await response.json();
        
                // Find the object that contains the comprehensive_report key
                const reportObj = responseData.find(item => item.comprehensive_report);
        
                if (reportObj && reportObj.comprehensive_report) {
                    setAnalysisResults(reportObj.comprehensive_report);
                } else {
                    setAnalysisResults(null);  // Clear the results if the response does not contain comprehensive_report
                }
            } catch (error) {
                console.error('Error during analysis:', error);
                setAnalysisError('Failed to analyze the document. Please try again.');
            } finally {
                setIsAnalyzing(false); // Reset analysis status
            }
        };
        
    // State to manage expanded reports. Object keys are report indices, and values are boolean flags.
    const [expandedReports, setExpandedReports] = useState({});

    // Function to toggle report expansion
    const toggleReportExpansion = (index) => {
        setExpandedReports(prevState => ({
            ...prevState,
            [index]: !prevState[index]  // Toggle the boolean value for the given report index
        }));
    };

    const renderAnalysisReport = (report, index) => {
        const isExpanded = expandedReports[index]; // Check if the current report is expanded based on the `expandedReports` state
    
        // Common report header with expand/collapse toggle
        const reportHeader = (
            <h4 onClick={() => toggleReportExpansion(index)} style={{ cursor: 'pointer' }}>
                {report.sentiment ? `Analysis Report ${index + 1}` : `Verification Report ${index + 1}`}
                <span className="expand-collapse-icon">{isExpanded ? '−' : '+'}</span>
            </h4>
        );
    
        if (report.sentiment) {
            // Handle the detailed analysis object
            const sentiment = report.sentiment.join(', ');
            const emotions = report.emotions.join(', ');
            const topic = report.topic.join(', ');
            // Safely parse tags if it's a string or handle other types appropriately
            const tags = typeof report.tags === 'string' ? JSON.parse(report.tags).join(', ') : Array.isArray(report.tags) ? report.tags.join(', ') : 'N/A';
            const summary = report.summary.join(', ');
    
            return (
                <div key={index} className={`analysis-report ${isExpanded ? 'expanded' : ''}`}>
                    {reportHeader}
                    {isExpanded && (
                        <div>
                            <p><strong>Sentiment:</strong> {sentiment}</p>
                            <p><strong>Emotions:</strong> {emotions}</p>
                            <p><strong>Topic:</strong> {topic}</p>
                            <p><strong>Tags:</strong> {tags}</p>
                            <p><strong>Summary:</strong> {summary}</p>
                            {/* Add more fields as needed */}
                        </div>
                    )}
                </div>
            );
        } else if (report.evidence) {
            // Handle the verification object
            const evidence = report.evidence.join(', ');
            const keyEntities = report.key_entities ? report.key_entities.join(', ') : 'N/A';
            const sourceText = report.source.text;
    
            return (
                <div key={index} className={`verification-report ${isExpanded ? 'expanded' : ''}`}>
                    {reportHeader}
                    {isExpanded && (
                        <div>
                            <p><strong>Evidence:</strong> {evidence}</p>
                            <p><strong>Key Entities:</strong> {keyEntities}</p>
                            <p><strong>Source Text:</strong> {sourceText}</p>
                            {/* Add more fields as needed */}
                        </div>
                    )}
                </div>
            );
        } else {
            // Fallback for unexpected report object structure
            return <div key={index}>Unexpected report structure</div>;
        }
    };
    
            
        

        
        return (
            <div className={`result-card ${highlighted ? 'highlighted' : ''}`}>
                <div className="result-header">
                    {/* Toggle the 'active' class based on whether the filter is active */}
                    <button
                        className={`library-button ${isFilterActive('library_name', result.library_name) ? 'active' : ''}`}
                        onClick={(e) => handleFieldSelect(e, 'library_name', result)}
                    >
                        {formatLibraryName(result.library_name)}
                    </button>
                    <button
                        className={`file-button ${isFilterActive('file_source') ? 'active' : ''}`}
                        onClick={(e) => handleFieldSelect(e, 'file_source', result)}
                    >
                        {getFileName(result.file_source)}
                    </button>
                    <button
                        className={`page-button ${isFilterActive('page_num') ? 'active' : ''}`}
                        onClick={(e) => handleFieldSelect(e, 'page_num', result)}
                    >
                        Page: {result.page_num}
                    </button>
                    <div className="result-controls">
                        <button
                            className={`control-button expand-up ${isFilterActive('expand_up') ? 'active' : ''}`}
                            onClick={() => handleExpandText(result, 'before')}
                            title="Expand Up"
                        >
                            🔼
                        </button>
                        <button
                            className={`control-button expand-down ${isFilterActive('expand_down') ? 'active' : ''}`}
                            onClick={() => handleExpandText(result, 'after')}
                            title="Expand Down"
                        >
                            🔽
                        </button>
                        <button
                            className={`control-button expand_both ${isFilterActive('expand_both') ? 'active' : ''}`}
                            onClick={() => handleExpandText(result, 'both')}
                            title="Expand Both"
                        >
                            🔀
                        </button>
                        <button
                            className={`control-button add-to-chat ${isResultSelected ? 'active' : ''}`}
                            onClick={() => handleResultSelect(result)}
                            title={isResultSelected ? "Remove from Chat" : "Add to Chat"}
                        >
                            {isResultSelected ? "➖" : "➕"}
                        </button>

                    </div>
                </div>

                <div className="result-text" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded || result.text_snippet.length <= 100 ?
                        result.text_snippet :
                        `${result.text_snippet.substring(0, 400)}...`}
                </div>

                {/* Display expanded text if available */}
                {currentExpandedData.expanded_text_before && isExpanded && (
                    <div className="expanded-text before">
                        <strong>Before:</strong> <span>{currentExpandedData.expanded_text_before}</span>
                    </div>
                )}
                {currentExpandedData.expanded_text_after && isExpanded && (
                    <div className="expanded-text after">
                        <strong>After:</strong> <span>{currentExpandedData.expanded_text_after}</span>
                    </div>
                )}

                <div className="result-footer">
                    <a href="#" onClick={(e) => handleViewDocumentClick(e, result.file_url, result.page_num)}>View Document</a>
                    <button onClick={handleAnalyzeResult} disabled={isAnalyzing}>
                {isAnalyzing ? 'Analyzing...' : 'Analyze'}
            </button>

            {analysisError && <div className="analysis-error">{analysisError}</div>}

            {analysisResults && (
                <div className="analysis-results">
                    {analysisResults.map(renderAnalysisReport)}
                </div>
            )}
        </div>


        </div>
    );
}
    // --- Previous Searches Management ---

    const clearPreviousSearches = () => {
        const confirmClear = window.confirm("Are you sure you want to clear all previous searches?");
        if (confirmClear) {
            setPreviousSearches([]);
            localStorage.removeItem(PREVIOUS_SEARCHES_STORAGE_KEY);
        }
    };


    // --- Model and Chat State Management ---

    const [modelName, setModelName] = useState('gpt-4-1106-preview');
    const [generativeModels, setGenerativeModels] = useState([]);
    const [userQuery, setUserQuery] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [isProcessingChat, setIsProcessingChat] = useState(false);
    const [isProcessingSearch, setIsProcessingSearch] = useState(false);
    const chatWindowRef = useRef(null);

    // --- Model Fetching and Setup ---

    const fetchModels = async () => {
        try {
            const response = await fetchWithToken(`${API_URL}/api/models/list_models`);
            if (!response.ok) throw new Error('Failed to fetch models');
            const models = await response.json();

            const filteredModels = models.filter(model => (isOnline && model.model_category === 'generative-api') || (!isOnline && model.model_category === 'generative_local')).map(model => model.model_name);
            setGenerativeModels(filteredModels);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };

    useEffect(() => {
        const defaultModel = isOnline ? 'gpt-4-1106-preview' : 'llmware/bling-1b-0.1';
        setModelName(defaultModel);
        fetchModels();
    }, [API_URL, token, isOnline]);

    // --- Chat Submission Handling ---




    const handleSubmit = async (e, queryText = userQuery) => {
        if (e) e.preventDefault();  // Only call preventDefault if e is not null

        const trimmedQuery = queryText.trim();
        if (!trimmedQuery) return;

        setIsProcessingChat(true);

        const combinedQuery = {
            userQuery: trimmedQuery,
            chatList,
            modelName,
        };

        try {
            const response = await fetchWithToken(`${API_URL}/api/chat/simple_search_chat`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(combinedQuery),
            });

            if (!response.ok) throw new Error('Network response was not ok');
            const responseData = await response.json();

            const newUserMessage = { role: 'user', content: trimmedQuery, evidenceData: null };
            const newLlmMessages = responseData.llm_responses?.map((content, index) => ({
                role: 'ai',
                content,
                evidenceData: responseData.evidence_comparison_stats?.[index]?.[0],
                sourceReviews: responseData.evidence_comparison_stats?.[index]?.[0]?.source_review,
            })) || [];


            // Automatically highlight the first source_review item if it exists
            if (newLlmMessages.length > 0 && newLlmMessages[0].sourceReviews && newLlmMessages[0].sourceReviews.length > 0) {
                const firstReview = newLlmMessages[0].sourceReviews[0];
                highlightResultCard(firstReview.doc_id, firstReview.block_id);
            }

            setChatMessages(prevChatMessages => [...prevChatMessages, newUserMessage, ...newLlmMessages]);
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setIsProcessingChat(false);
        }
    };



    // --- Utility Functions ---

    function formatLibraryName(name) {
        // Regular expression to match Dropbox UIDs like '6596102591a092b79369f676'
        const dropboxUidRegex = /\b[0-9a-f]{24}\b/g;

        // Replace Dropbox UIDs with an empty string
        let formattedName = name.replace(dropboxUidRegex, '');

        // Replace underscores with slashes and hyphens with spaces
        formattedName = formattedName.replace(/_/g, '/').replace(/-/g, ' ');

        // Trim any leading or trailing whitespace
        formattedName = formattedName.trim();

        return formattedName;
    }

    const toggleFilter = (filterNumber) => {
        setActiveFilter(prev => prev === filterNumber ? null : filterNumber);
    };

    // --- Chat UI Management ---

    /*     const scrollToBottom = () => {
            chatWindowRef.current?.scrollIntoView({ behavior: 'smooth' });
        };
    
        useEffect(scrollToBottom, [chatMessages]); */

    const renderedResults = messages.filter(msg => msg.type !== 'query');


    // --- Highlighted Chat Components ---

    const [highlightedItemIndex, setHighlightedItemIndex] = useState(null);
    const [highlightedResultIndex, setHighlightedResultIndex] = useState(null);



    const highlightChatListItem = (docId, blockId) => {
        const targetIndex = chatList.findIndex(item =>
            item.doc_id === docId && item.block_id === blockId
        );

        // Toggle the highlight off if the same item is clicked again
        if (highlightedItemIndex === targetIndex) {
            setHighlightedItemIndex(null);
        } else {
            setHighlightedItemIndex(targetIndex);
        }
    };


    const highlightResultCard = (docId, blockId) => {
        const targetIndex = renderedResults.findIndex(msg =>
            msg.data.doc_id === docId && msg.data.block_id === blockId
        );

        // Toggle the highlight off if the same item is clicked again
        if (highlightedResultIndex === targetIndex) {
            setHighlightedResultIndex(null);
        } else {
            setHighlightedResultIndex(targetIndex);
        }
    };





    // --- Search History Management ---

    const [searchHistory, setSearchHistory] = useState([]);

    const handleNewSearch = (searchResults, query) => {
        const searchId = Date.now(); // Unique ID for each search
        setSearchHistory(prevHistory => [{ id: searchId, query, results: searchResults }, ...prevHistory]);
    };

    const goToSearch = (searchId) => {
        const previousSearch = previousSearches.find(search => search.id === searchId);
        if (previousSearch) {
            setMessages(previousSearch.results);
        } else {
            console.error('Error: Search ID not found in previous searches');
        }
    };

    // --- AutoChat Feature ---



    const toggleAutoChat = () => {
        setIsAutoChatEnabled(prevState => !prevState);
    };

    // --- Evidence Details Management ---

    const [expandedMessageIndex, setExpandedMessageIndex] = useState(null);

    const getTabColor = (percentDisplay) => {
        const percent = parseFloat(percentDisplay);
        if (percent < 40) return "red";
        if (percent < 75) return "orange";
        if (percent < 99) return "green";
        return "gold"; // For 99% and above
    };

    const handleTabClick = (index) => {
        setExpandedMessageIndex(prevIndex => prevIndex === index ? null : index);
    };


    const [showSortingOptions, setShowSortingOptions] = useState(false);
    const toggleSortingOptions = () => {
        setShowSortingOptions(!showSortingOptions);
    };



    return (
        <div className="search-and-chat">
            {/*             

            <div className="button-section">
              


                <div className="button-group-right">
                    <button onClick={toggleChatMode}>{isChatMode ? "Chat" : "Search"}</button>
                    <button onClick={toggleOnlineStatus}>{isOnline ? "Online" : "Offline"}</button>
                    <button onClick={toggleAutoChat}>
                        {isAutoChatEnabled ? "AutoChat ON" : "AutoChat OFF"}
                    </button>
                </div>
            </div>

 */}

            {/* Main Content Area */}
            <div className="main-content">
                {/* Combined Search Bar and Search Section */}
                <div className={`search-section ${isLeftExpanded ? 'expanded' : ''}`}>

                    {/* Search Bar Section */}
                    <div className="search-bar-section">
                        <form onSubmit={handleSearch} className="search-form">
                            <button type="button" className="previous-searches-toggle" onClick={() => setShowPreviousSearches(!showPreviousSearches)}>
                                <img src={historyIcon} alt="History" className="icon-history" /> {/* Use the imported image in an <img> tag */}
                            </button>
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button type="button" className="sort-button" onClick={toggleSortingOptions}>
                                <img src={sortIcon} alt="Sort" className="icon-sort" /> {/* Use the imported image in an <img> tag */}
                            </button>
                            <button type="submit" className="go-button">Go</button>
                        </form>
                        {showSortingOptions && (
                            <div className="sorting-options-dropdown">
                                {/* Sorting Criteria */}
                                <select value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)} className="sorting-select">
                                    <option value="">Select sorting criteria</option>
                                    <option value="match_status">Best match</option>
                                    <option value="library_name">Library Name</option>
                                </select>
                                {/* Sorting Order */}
                                <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className="sorting-select">
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </div>
                        )}
                        {/* Previous Searches Dropdown */}
                        {showPreviousSearches && (
                            <div className="previous-searches-list-container">
                                <ul className="previous-searches-list">
                                    {previousSearches.map((search, index) => (
                                        <li key={index} onClick={() => onSearchClick(search.query)}>
                                            {search.query}
                                        </li>
                                    ))}
                                </ul>
                                {previousSearches.length > 0 && (
                                    <button className="clear-searches-button" onClick={clearPreviousSearches}>
                                        Clear Searches
                                    </button>
                                )}
                            </div>
                        )}
                    </div>



                    {/* Tabbed Filters Interface */}
                    <div className="tabbed-filters-interface">
                        {/* Tabs for Selecting Filters */}
                        <div className="filter-tabs">
                            <button className={`filter-tab ${activeFilter === 1 ? 'active' : ''}`} onClick={() => toggleFilter(1)}>
                                Library Filters
                            </button>
                            <button className={`filter-tab ${activeFilter === 2 ? 'active' : ''}`} onClick={() => toggleFilter(2)}>
                                Textual Filters
                            </button>
                            <button className={`filter-tab ${activeFilter === 3 ? 'active' : ''}`} onClick={() => toggleFilter(3)}>
                                Date Filters
                            </button>
                        </div>

                        {/* Filter Details Panels */}
                        <div className={`filter-details ${activeFilter === 1 ? 'show' : ''}`}>

                            <div className="library-filters">
                                <h3>Library Filters</h3>
                                {libraries.map(library => (
                                    <div key={library.name} className="filter-item">
                                        <input
                                            id={`filter-${library.name}`}
                                            type="checkbox"
                                            checked={selectedLibraries.includes(library.name)}
                                            onChange={() => handleToggleLibrary(library.name)}
                                        />
                                        <label htmlFor={`filter-${library.name}`}>{formatLibraryName(library.name)}</label>
                                    </div>
                                ))}
                                <div className="filter-actions">
                                    <button onClick={selectAllLibraries} title="Select all libraries">
                                        <i className="icon-select-all"></i> Select All
                                    </button>
                                    <button onClick={unselectAllLibraries} title="Unselect all libraries">
                                        <i className="icon-unselect-all"></i> Unselect All
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className={`filter-details ${activeFilter === 2 ? 'show' : ''}`}>
                            <h3>Textual Filters</h3>
                            <input type="number" placeholder="Document ID" value={docIdFilter} onChange={(e) => setDocIdFilter(e.target.value)} />
                            <input type="number" placeholder="Block ID" value={blockIdFilter} onChange={(e) => setBlockIdFilter(e.target.value)} />
                            <input type="number" placeholder="Page Number" value={pageNumFilter} onChange={(e) => setPageNumFilter(e.target.value)} />
                            <input type="text" placeholder="Text Snippet" value={textSnippetFilter} onChange={(e) => setTextSnippetFilter(e.target.value)} />
                            <input type="text" placeholder="File Source" value={fileSourceFilter} onChange={(e) => setFileSourceFilter(e.target.value)} />
                            <input type="text" placeholder="File Type" value={fileTypeFilter} onChange={(e) => setFileTypeFilter(e.target.value)} />
                            <input type="text" placeholder="Author/Speaker" value={authorOrSpeakerFilter} onChange={(e) => setAuthorOrSpeakerFilter(e.target.value)} />
                        </div>
                        <div className={`filter-details ${activeFilter === 3 ? 'show' : ''}`}>
                            <h3>Date Filters</h3>
                            <div className="date-filter-item">
                                <label htmlFor="added-to-collection">Added to Collection:</label>
                                <input type="date" id="added-to-collection" value={formatDateForInput(addedToCollectionFilter)} onChange={(e) => setAddedToCollectionFilter(e.target.value)} />
                            </div>
                            <div className="date-filter-item">
                                <label htmlFor="modified-date">Modified Date:</label>
                                <input type="date" id="modified-date" value={formatDateForInput(modifiedDateFilter)} onChange={(e) => setModifiedDateFilter(e.target.value)} />
                            </div>
                            <div className="date-filter-item">
                                <label htmlFor="created-date">Created Date:</label>
                                <input type="date" id="created-date" value={formatDateForInput(createdDateFilter)} onChange={(e) => setCreatedDateFilter(e.target.value)} />
                            </div>
                        </div>
                    </div>


                    {/* Results Card */}
                    <div className="results-card">
                        {isProcessingSearch ? (
                            <div className="loading-spinner-container">
                                <div className="loading-spinner"></div>
                            </div>
                        ) : (
                            <div className="result-list">
                                {renderedResults.map((msg, index) => (
                                    <ResultCard
                                        key={index}
                                        result={msg.data}
                                        handleResultSelect={(result) => handleResultSelect(result, msg.searchId)}
                                        handleFieldSelect={handleFieldSelect}
                                        handleExpandText={handleExpandText}
                                        expandedTextData={expandedTextData}
                                        chatList={chatList}
                                        activeFilters={activeFilters}
                                        docIdFilter={docIdFilter}
                                        pageNumFilter={pageNumFilter}
                                        textSnippetFilter={textSnippetFilter}
                                        fileSourceFilter={fileSourceFilter}
                                        fileTypeFilter={fileTypeFilter}
                                        authorOrSpeakerFilter={authorOrSpeakerFilter}
                                        libraryNameFilter={selectedLibraries}
                                        addedToCollectionFilter={addedToCollectionFilter}
                                        modifiedDateFilter={modifiedDateFilter}
                                        createdDateFilter={createdDateFilter}
                                        highlighted={index === highlightedResultIndex}
                                    />
                                ))}
                            </div>
                        )}
                    </div>



                    {/* Expand Button for Search Section */}
                    <button className="expand-button left" onClick={toggleLeftExpansion}>
                        {isLeftExpanded ? "←" : "→"}
                    </button>
                </div>


                {/* View and Chat Section */}
                <div className={`view-chat-section ${isRightExpanded ? 'expanded' : ''}`}>
                    {/* Toggle Chat Mode Button */}
                    <button onClick={toggleChatMode} className="toggle-chat-mode-button">
                        <img
                            src={toggleChatModeIcon}
                            alt="Toggle Chat Mode"
                            className="icon-toggle"
                        />
                    </button>
                    {isChatMode ? (
                        <div className="chat-interface">


                            <form onSubmit={handleSubmit} className="chat-form">
                                {/* Online Status Toggle */}
                                <div className="online-status-container">
                                    <label className="online-status-toggle">
                                        <input type="checkbox" checked={isOnline} onChange={toggleOnlineStatus} />
                                        <span className="slider round"></span>
                                    </label>
                                    <span className={`status-indicator ${isOnline ? 'online' : 'offline'}`}>
                                        {isOnline ? 'Online' : 'Offline'}
                                    </span>
                                </div>

                                {/* Model Selector */}
                                <div className="select-wrapper">
                                    <select value={modelName} onChange={(e) => setModelName(e.target.value)} className="styled-select">
                                        <option value="">Select a generative model</option>
                                        {generativeModels.map((model, index) => (
                                            <option key={index} value={model}>{model}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* Conditionally render chat input and submit button */}
                                {chatList.length > 0 && (
                                    <>
                                        {/* Chat Input */}
                                        <input
                                            type="text"
                                            value={userQuery}
                                            onChange={(e) => setUserQuery(e.target.value)}
                                            placeholder="Additional queries..."
                                            className="chat-input"
                                        />

                                        {/* Submit button */}
                                        <button type="submit" className="send-query-button">Send</button>
                                    </>
                                )}
                            </form>


                            {/* Chat Window */}
                            <div className="simpleSearchChat-window" ref={chatWindowRef}>
                                {/* Existing chat messages */}
                                {chatMessages.map((msg, index) => (
                                    <div key={index} className={`message-wrapper ${msg.role}`}>
                                        <div
                                            className={`message ${msg.role} ${msg.evidenceData ? `evidence-tab ${getTabColor(msg.evidenceData.comparison_stats.percent_display)}` : ''}`}
                                            data-confidence={msg.evidenceData ? msg.evidenceData.comparison_stats.percent_display : ''}
                                            onClick={() => msg.evidenceData && handleTabClick(index)}
                                            title={msg.evidenceData ? "Click for more details" : ''}
                                        >
                                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                                        </div>
                                        {expandedMessageIndex === index && msg.evidenceData && (
                                            <div className="evidence-details-card">
                                                <div className="card-header">
                                                    <h3>Evidence Details</h3>
                                                    <span className="confidence-level">Confidence: {msg.evidenceData.comparison_stats.percent_display}</span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="words-sections-container">
                                                        <div className="section confirmed-words">
                                                            <h4><i className="icon-confirmed"></i> Matched Words</h4>
                                                            <ul>
                                                                {msg.evidenceData.comparison_stats.confirmed_words.map((word, i) => (
                                                                    <li key={i}>{word}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className="section unmatched-words">
                                                            <h4><i className="icon-unmatched"></i> Unmatched Words</h4>
                                                            <ul>
                                                                {msg.evidenceData.comparison_stats.unconfirmed_words.map((word, i) => (
                                                                    <li key={i}>{word}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="section key-points">
                                                        <h4><i className="icon-keypoint"></i> Key Points</h4>
                                                        {msg.evidenceData.comparison_stats.key_point_list.map((kp, i) => (
                                                            <div key={i} className="key-point">
                                                                <p>{kp.key_point}</p>
                                                                <p className="verified-match">Verified Match: {kp.verified_match ? 'Yes' : 'No'}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {msg.sourceReviews && (
                                                        <div className="section source-matches">
                                                            <h4>Source Matches</h4>
                                                            {msg.sourceReviews.map((review, reviewIndex) => (
                                                                <div key={reviewIndex} className="source-match">
                                                                    <p className="match-text">"{review.text}"</p>
                                                                    <p className="match-details">Score: {review.match_score.toFixed(2)}, Page: {review.page_num}</p>
                                                                    {/* Updated to Button for Interaction */}
                                                                    {/*                                                                     <button
                                                                        className="highlight-button"
                                                                        onClick={() => highlightChatListItem(review.doc_id, review.block_id)}
                                                                    >
                                                                        Highlight in Chat List
                                                                    </button> */}
                                                                    <button
                                                                        className="highlight-button"
                                                                        onClick={() => highlightResultCard(review.doc_id, review.block_id)}
                                                                    >
                                                                        Highlight Result Card
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}

                                {/* Loading Spinner */}
                                {isProcessingChat && (
                                    <div className="loading-spinner-container">
                                        <div className="loading-spinner"></div>
                                    </div>
                                )}

                                {/* Expandable Chat List Container */}
                                <div className="chat-list-expand-container">
                                    {/* Toggle Button for Chat List */}
                                    {chatList.length > 0 && (
                                        <button className="chat-list-expand-toggle" onClick={toggleChatListPanel}>
                                            {/* You can use an icon or text like "^" */}
                                            <span className="expand-icon">V</span>
                                        </button>
                                    )}

                                    {/* Chat List Panel */}
                                    {showChatListPanel && (
                                        <div className="chat-list-panel">
                                            {chatList.map((item, index) => (
                                                <div key={index} className={`chat-list-item ${index === highlightedItemIndex ? 'highlighted' : ''}`}>

                                                    <div className="chat-list-item-content">
                                                        <div className="item-details">
                                                            <span className="library-name">{item.library_name}</span>
                                                            <span className="file-name">{getFileName(item.file_source)}</span>
                                                            <span className="page-number">Page: {item.page_num}</span>
                                                        </div>
                                                        <div className="snippet-text">{item.text_snippet.substring(0, 100)}...</div>
                                                    </div>
                                                    <div className="chat-list-item-actions">
                                                        <button onClick={() => goToSearch(item.searchId)} className="go-to-search-button" title="Go to Search">
                                                            🔍
                                                        </button>
                                                        <button onClick={() => removeFromChatList(item)} className="remove-chat-list-item" title="Remove">
                                                            ❌
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>


                                    )}
                                </div>
                            </div>
                        </div>


                    ) : (
                        <iframe
                            className="document-viewer"
                            src={iframeSrc || "about:blank"} // Use the state variable as the src attribute for the iframe, with a default value
                            title="Document Viewer"
                            width="100%"
                            height="100%"
                        ></iframe>
                    )}
                    {/* Expand Button for View and Chat Section */}
                    <button className="expand-button right" onClick={toggleRightExpansion}>
                        {isRightExpanded ? "→" : "←"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchAndChat;
// RagQueryChat.js
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../auth/AuthContext'; // Import useAuth hook
import './RagQueryChat.css';

const RagQueryChat = ({ selectedBot }) => {
  const { token } = useAuth(); // Access token from AuthContext
  const [libraryName, setLibraryName] = useState('default_library');
  const [modelName, setModelName] = useState('gpt-4-1106-preview');
  const [embeddingModel, setEmbeddingModel] = useState('text-embedding-ada-002');
  const [generativeModels, setGenerativeModels] = useState([]);
  const [embeddingModels, setEmbeddingModels] = useState([]);
  const [availableLibraries, setAvailableLibraries] = useState([]);
  const [resultCount, setResultCount] = useState(5); // Default result count
  const [searchQuery, setSearchQuery] = useState('');
  const [promptText, setPromptText] = useState('');
  const [messages, setMessages] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  // A helper function to include the token in API requests
  const fetchWithToken = (url, options = {}) => {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}` // Include token in headers
    };
    return fetch(url, options);
  };

  useEffect(() => {
    const fetchModelsAndLibraries = async () => {
      try {
        // Fetch Models
        const modelsResponse = await fetchWithToken(`${API_URL}/api/models/list_models`);
        if (!modelsResponse.ok) throw new Error('Failed to fetch models');
        const models = await modelsResponse.json();

        // Filter and set generative models
        const filteredGenerativeModels = models.filter(model =>
          model.model_category === 'generative-api' || model.model_category === 'generative_local'
        ).map(model => model.model_name);
        setGenerativeModels(filteredGenerativeModels);

        // Filter and set embedding models
        const filteredEmbeddingModels = models.filter(model =>
          model.model_category === 'embedding'
        ).map(model => model.model_name);
        setEmbeddingModels(filteredEmbeddingModels);

        // Set default models
        setModelName(filteredGenerativeModels.includes('gpt-4-1106-preview') ? 'gpt-4-1106-preview' : filteredGenerativeModels[0] || '');
        setEmbeddingModel(filteredEmbeddingModels.includes('text-embedding-ada-002') ? 'text-embedding-ada-002' : filteredEmbeddingModels[0] || '');

        // Fetch Libraries
        const libraryResponse = await fetchWithToken(`${API_URL}/api/libraries/load-libraries`);
        if (!libraryResponse.ok) throw new Error('Failed to fetch libraries');
        const libraries = await libraryResponse.json();
        setAvailableLibraries(libraries.map(library => ({
          ...library,
          name: library.name.replace(/_/g, '/').replace(/-/g, ' ')
        })));
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchModelsAndLibraries();
  }, [API_URL, token]); // Include token as a dependency

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim() || !promptText.trim()) return;

    const userMessage = { role: 'user', content: `${searchQuery} - ${promptText}` };
    setMessages(messages => [...messages, userMessage]);

    try {
      const requestBody = {
        library_name: libraryName,
        model_name: modelName,
        search_query: searchQuery,
        prompt_text: promptText,
        embedding_model: embeddingModel,
        result_count: resultCount
      };

      const response = await fetchWithToken(`${API_URL}/api/chat/ragQuery`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      const responseData = await response.json();
      const aiMessage = {
        role: 'ai',
        content: formatResponse(responseData) // Call the formatting function
      };
      setMessages(messages => [...messages, aiMessage]);
    } catch (error) {
      console.error('Fetch error:', error);
    }

    setSearchQuery('');
    setPromptText('');
  };

  // Function to format the JSON response into a Markdown string
  const formatResponse = (responseData) => {
    let formattedResponse = '';

    // Format LLM Responses
    responseData.llm_responses.forEach((response, index) => {
      formattedResponse += `## LLM Response ${index + 1}\n\n${response}\n\n`;
    });

    // Format Query Results
    if (responseData.query_results && responseData.query_results.length > 0) {
      formattedResponse += `### Query Results\n\n`;
      responseData.query_results.forEach((result, index) => {
        formattedResponse += `#### Result ${index + 1}\n`;
        formattedResponse += `**Distance:** ${result.distance}  \n`; // two spaces before newline
        // URL encode the filename
        const encodedFileName = encodeURIComponent(getFileName(result.file_source));
        formattedResponse += `**File Source:** [${getFileName(result.file_source)}](${result.file_url.replace(encodeURIComponent(getFileName(result.file_source)), encodedFileName)})\n`;
        formattedResponse += `**Page Number:** ${result.page_num}  \n`; // two spaces before newline
        formattedResponse += `**Text:** ${result.text}\n\n`; // blank line for a new paragraph
        formattedResponse += `---\n\n`; // thematic break for separation
      });
    }

    // Add Report File Link
    if (responseData.report_file) {
      formattedResponse += `[Download Report](${getFileName(responseData.report_file)})\n`;
    }

    return formattedResponse;
  };


  // Helper function to extract the file name from the file path
  const getFileName = (filePath) => {
    return filePath.split('\\').pop().split('/').pop();
  };

  return (
    <>
      <h1>{selectedBot} RAG Query Chat</h1>
      <form onSubmit={handleSubmit}>
        <div className="select-wrapper">
          <select value={libraryName} onChange={(e) => setLibraryName(e.target.value)} className="styled-select">
            <option value="">Select a library</option>
            {availableLibraries.map((library, index) => (
              <option key={index} value={library.name}>{library.name}</option>
            ))}
          </select>
        </div>
        <div className="select-wrapper">
          <select value={modelName} onChange={(e) => setModelName(e.target.value)} className="styled-select">
            <option value="">Select a generative model</option>
            {generativeModels.map((model, index) => (
              <option key={index} value={model}>{model}</option>
            ))}
          </select>
        </div>
        <div className="select-wrapper">
          <select value={embeddingModel} onChange={(e) => setEmbeddingModel(e.target.value)} className="styled-select">
            <option value="">Select an embedding model</option>
            {embeddingModels.map((model, index) => (
              <option key={index} value={model}>{model}</option>
            ))}
          </select>
        </div>
        <div className="select-wrapper">
          <select value={resultCount} onChange={(e) => setResultCount(e.target.value)} className="styled-select">
            {[5, 10, 15, 20].map(count => (
              <option key={count} value={count}>{count} Results</option>
            ))}
          </select>
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Enter your search query..."
        />
        <input
          type="text"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          placeholder="Enter your prompt text..."
        />
        <button type="submit">Send Query</button>
      </form>
      <div className="ragQueryChat-window">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            <ReactMarkdown>{msg.content}</ReactMarkdown>
          </div>
        ))}
      </div>
    </>
  );
};

export default RagQueryChat;

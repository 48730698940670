import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../auth/AuthContext';
import './TenantUsers.css';

const TenantUsers = ({ selectedTenant }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [newUserRole, setNewUserRole] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [ssoEnabled, setSsoEnabled] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const fetchWithToken = async (url, options = {}) => {
    return fetch(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${token}`,
        ...options.headers,
      },
    });
  };

  const fetchUsers = useCallback(async () => {
    if (!selectedTenant) return;
    const response = await fetchWithToken(`${API_URL}/api/auth_config/user_config/users?tenantId=${selectedTenant}`);
    if (response.ok) {
      const data = await response.json();
      setUsers(data);
    } else {
      console.error('Failed to fetch users');
    }
  }, [selectedTenant, token, API_URL]);

  const createUser = async () => {
    const userPayload = {
      username: newUsername,
      role: newUserRole,
      tenantId: selectedTenant,
      ...(ssoEnabled ? { ssoEnabled } : { password: newPassword }),
    };

    const response = await fetchWithToken(`${API_URL}/api/auth_config/user_config/users/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userPayload),
    });

    if (response.ok) {
      setNewUsername('');
      setNewUserRole('');
      setNewPassword('');
      setSsoEnabled(false);
      fetchUsers();
    } else {
      console.error('Failed to create user');
    }
  };

  const updateUser = async (userId, updatedUser) => {
    const response = await fetchWithToken(`${API_URL}/api/auth_config/user_config/users/${userId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedUser),
    });

    if (response.ok) {
      fetchUsers();
    } else {
      console.error('Failed to update user');
    }
  };

  const deleteUser = async (userId) => {
    const response = await fetchWithToken(`${API_URL}/api/auth_config/user_config/users/${userId}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      fetchUsers();
    } else {
      console.error('Failed to delete user');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div className="group-content">
    <div className="tenant-users">
      <h2>Users in Tenant: {selectedTenant}</h2>
      <div className="create-user-form">
        <input type="text" placeholder="Username" value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
        <input type="text" placeholder="Role" value={newUserRole} onChange={(e) => setNewUserRole(e.target.value)} />
        <input type="password" placeholder="Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} disabled={ssoEnabled} />
        <label>
          <input type="checkbox" checked={ssoEnabled} onChange={(e) => setSsoEnabled(e.target.checked)} /> SSO Enabled
        </label>
        <button onClick={createUser}>Create User</button>
      </div>
      <table className="users-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.role}</td>
              <td>
                <button onClick={() => updateUser(user._id, { /* Updated user data here */ })}>Edit</button>
                <button onClick={() => deleteUser(user._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default TenantUsers;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import './DropboxManagement.css';


const DropboxManagement = () => {
  const { token, user } = useAuth();
  const [dropboxFiles, setDropboxFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState('');
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const fetchWithToken = (url, options = {}) => {
    options.headers = { ...options.headers, 'Authorization': `Bearer ${token}` };
    return fetch(url, options);
  };

  const fetchDropboxFiles = (path = '') => {
    if (user && user.userId) {
      fetchWithToken(`${API_URL}/api/utils/dropbox_file_utils/list-files?userId=${user.userId}&path=${encodeURIComponent(path)}`)
        .then(response => response.json())
        .then(data => setDropboxFiles(data.files))
        .catch(error => console.error('Error fetching Dropbox files:', error));
    }
  };

  useEffect(() => { fetchDropboxFiles(); }, [API_URL, token, user]);

  const handleFileClick = (file) => {
    if (file.type === 'folder') {
      const newPath = currentPath ? `${currentPath}/${file.name}` : file.name;
      setCurrentPath(newPath);
      fetchDropboxFiles(newPath);
    }
  };

  const navigateBack = () => {
    const pathSegments = currentPath.split('/');
    pathSegments.pop();
    setCurrentPath(pathSegments.join('/'));
    fetchDropboxFiles(pathSegments.join('/'));
  };

  const handleCreateEmbeddings = () => {
    const fullPath = currentPath ? `${currentPath}` : '/';
    fetchWithToken(`${API_URL}/api/utils/dropbox-create-embeddings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ path: fullPath, userId: user.userId })
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error creating embeddings:', error));
  };

  const renderDropboxFiles = () => {
    if (!dropboxFiles || dropboxFiles.length === 0) {
      return <div>No files found in Dropbox.</div>;
    }
    return dropboxFiles.map((file, index) => (
      <div key={index} className="dropbox-entry" onClick={() => handleFileClick(file)}>
        <span className="dropbox-entry-name">{file.name}</span>
      </div>
    ));
  };

  // Sidebar for Dropbox
  const DropboxSidebar = () => (
    <aside className="dropbox-sidebar">
      <h2>Dropbox</h2>
      <div className="dropbox-navigation">
        <button onClick={() => fetchDropboxFiles('')} className="dropbox-button">
          Home
        </button>
      </div>
    </aside>
  );

  return (
    <div className="dropbox-management-container">
      <DropboxSidebar />
      <section className="dropbox-content">
        <div className="dropbox-path-toolbar">
          {currentPath && <button onClick={navigateBack} className="back-button">Back</button>}
          <h3>{currentPath ? `Dropbox/${currentPath}` : 'Dropbox'}</h3>
        </div>
        <div className="dropbox-files">{renderDropboxFiles()}</div>
        <div className="create-embeddings-container">
          <button onClick={handleCreateEmbeddings}>Create Embeddings for {currentPath}</button>
        </div>
      </section>
    </div>
  );
};

export default DropboxManagement;

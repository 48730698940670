import React, { useState, useEffect } from 'react';
import './TenantConfig.css'; // Ensure correct path

const TenantConfig = ({ tenantConfig, updateTenantConfig }) => {
  const [ssoUrl, setSsoUrl] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [ssoCertificate, setSsoCertificate] = useState('');
  const [issuer, setIssuer] = useState(''); // State for issuer

  // Update local state when tenantConfig prop changes
  useEffect(() => {
    setSsoUrl(tenantConfig.ssoUrl || '');
    setClientId(tenantConfig.clientId || '');
    setClientSecret(tenantConfig.clientSecret || '');
    setSsoCertificate(tenantConfig.ssoCertificate || '');
    setIssuer(tenantConfig.issuer || ''); // Update state with issuer from props
  }, [tenantConfig]);

  const handleSaveConfig = () => {
    const updatedConfig = {
      ssoUrl,
      clientId,
      clientSecret,
      ssoCertificate,
      issuer, // Include issuer in the updated config
    };
    updateTenantConfig(updatedConfig);
  };

  return (
    <div className="tenant-config">
        <h2>Tenant Configuration</h2>
        <div className="config-form">
          <div className="form-field">
            <label htmlFor="ssoUrl">SSO URL:</label>
            <input
              id="ssoUrl"
              value={ssoUrl}
              onChange={(e) => setSsoUrl(e.target.value)}
              placeholder="Enter SSO URL"
            />
          </div>

          <div className="form-field">
            <label htmlFor="clientId">Client ID:</label>
            <input
              id="clientId"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              placeholder="Enter Client ID"
            />
          </div>

          <div className="form-field">
            <label htmlFor="clientSecret">Client Secret:</label>
            <input
              id="clientSecret"
              type="password"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              placeholder="Enter Client Secret"
            />
          </div>

          <div className="form-field">
            <label htmlFor="ssoCertificate">SSO Certificate:</label>
            <textarea
              id="ssoCertificate"
              value={ssoCertificate}
              onChange={(e) => setSsoCertificate(e.target.value)}
              placeholder="Paste SSO Certificate here"
            />
          </div>

          <div className="form-field">
            <label htmlFor="issuer">Issuer:</label>
            <input
              id="issuer"
              value={issuer}
              onChange={(e) => setIssuer(e.target.value)}
              placeholder="Enter Issuer"
            />
          </div>

          <div className="group-actions">
            <button onClick={handleSaveConfig}>Save Configuration</button>
          </div>
        </div>
      </div>
  );
};

export default TenantConfig;

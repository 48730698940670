import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext'; // Import useAuth hook
import './LibraryManagement.css';

const LibraryManagement = () => {
  const { token } = useAuth(); // Access token from AuthContext
  const [libraries, setLibraries] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState('');
  const [libraryFiles, setLibraryFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
  const [currentPath, setCurrentPath] = useState(''); // Current path within the library

  const [isProcessing, setIsProcessing] = useState(false);
  const [embeddingResults, setEmbeddingResults] = useState(null);



  // A helper function to include the token in API requests
  const fetchWithToken = (url, options = {}) => {
    const headers = new Headers(options.headers || {});
    headers.append('Authorization', `Bearer ${token}`);

    return fetch(url, { ...options, headers });
  };


  // Function to create embeddings for the current location in the library
  const handleCreateEmbeddings = () => {
    setIsProcessing(true);
    const fullPath = currentPath ? `${selectedLibrary}/${currentPath}` : selectedLibrary;

    fetchWithToken(`${API_URL}/api/libraries/create-embeddings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ path: fullPath })
    })
      .then(response => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then(data => {
            // Process JSON data
            setEmbeddingResults(data); // Update the state with the received data
            console.log(data.message);
          });
        } else {
          return response.text().then(text => {
            // Process non-JSON data
            setEmbeddingResults(text)
            console.log("Non-JSON response:", text);
            // Optionally update the state with non-JSON response if needed
          });
        }
      })
      .catch(error => console.error('Error creating embeddings:', error))
      .finally(() => setIsProcessing(false));
  };



  // Fetch libraries on component mount
  useEffect(() => {
    fetchWithToken(`${API_URL}/api/libraries/list-libraries-folders`)
      .then(response => response.json())
      .then(data => setLibraries(data.libraries))
      .catch(error => console.error('Error fetching libraries:', error));
  }, [API_URL, token]);

  // Handle library selection
  const handleLibrarySelect = (library) => {
    setSelectedLibrary(library);
    fetchLibraryFiles(library);
  };

  // Handle file upload for the selected library
  const handleUpload = () => {
    setIsProcessing(true);
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    const uploadUrl = currentPath ? `${API_URL}/api/libraries/upload-files/${selectedLibrary}/${currentPath}` : `${API_URL}/api/libraries/upload-files/${selectedLibrary}`;

    fetchWithToken(uploadUrl, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(() => fetchLibraryFiles(selectedLibrary, currentPath))
      .catch(error => console.error('Error uploading file:', error))
      .finally(() => setIsProcessing(false)); // Moved inside finally to ensure it's called last
  };

  // Handle file deletion
  const handleDelete = (fileName) => {
    // Include the currentPath in the API endpoint if it exists
    const deleteEndpoint = currentPath
      ? `${API_URL}/api/libraries/delete-files/${selectedLibrary}/${currentPath}`
      : `${API_URL}/api/libraries/delete-files/${selectedLibrary}`;

    fetch(deleteEndpoint, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fileName })
    })
      .then(response => response.json())
      .then(() => fetchLibraryFiles(selectedLibrary, currentPath)) // Ensure to fetch files for current path after deletion
      .catch(error => console.error('Error deleting file:', error));
  };


  // Handle file selection
  const handleFiles = (files) => {
    setSelectedFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior (prevent file from being opened)
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files); // Process the dropped files
  };

  const handleFileSelect = (event) => {
    handleFiles(event.target.files);
  };

  // Render libraries list
  const renderLibraries = () => {
    return libraries.map(library => (
      <div key={library} className="library-entry">
        {library}
        <button onClick={() => handleLibrarySelect(library)}>Open</button>
      </div>
    ));
  };

  const fetchLibraryFiles = (libraryName, subpath = '') => {
    const fullPath = subpath ? `${libraryName}/${subpath}` : libraryName;
    console.log(`Fetching files for: ${fullPath}`); // Debugging log
    fetchWithToken(`${API_URL}/api/libraries/list-files/${fullPath}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched items:', data.items); // Debugging log
        setLibraryFiles(data.items);
      })
      .catch(error => console.error('Error fetching files in library:', error));
  };


  // Navigate into a directory
  const navigateToDirectory = (directoryName) => {
    const newPath = currentPath ? `${currentPath}/${directoryName}` : directoryName;
    console.log(`Navigating to directory: ${newPath}`); // Debugging log
    setCurrentPath(newPath);
    fetchLibraryFiles(selectedLibrary, newPath);
  };

  const navigateBack = () => {
    const pathSegments = currentPath.split('/');
    pathSegments.pop();
    const newPath = pathSegments.join('/');
    console.log(`Navigating back to: ${newPath}`); // Debugging log
    setCurrentPath(newPath);
    fetchLibraryFiles(selectedLibrary, newPath);
  };

  // Render sublibraries in the selected library
  const renderSublibraries = () => {
    return libraryFiles.filter(item => item.type === 'directory').map(directory => (
      <div key={directory.name} className="library-directory-entry">
        {directory.name}
        <button onClick={() => navigateToDirectory(directory.name)}>Open</button>
      </div>
    ));
  };

  // Render files in the selected library
  const renderLibraryFiles = () => {
    return libraryFiles.filter(item => item.type === 'file').map(file => (
      <div key={file.name} className="library-file-entry">
        {file.name}
        <button onClick={() => handleDelete(file.name)}>Delete</button>
      </div>
    ));
  };

  // Render the "Create Embeddings" button only if a library is selected
  const renderCreateEmbeddingsButton = () => {
    return selectedLibrary && (
      <button onClick={handleCreateEmbeddings}>Create Embeddings for {selectedLibrary} - {currentPath}</button>
    );
  };

  // Function to determine the current location for display
  const getCurrentLocationDisplay = () => {
    if (!selectedLibrary) return '';
    if (!currentPath) return selectedLibrary;
    return `${selectedLibrary}/${currentPath}`;
  };


// New component to represent each directory or file entry
const LibraryEntry = ({ name, onOpen, onDelete, isDirectory }) => (
  <div className="library-entry">
    <span className="library-entry-name">{name}</span>
    {isDirectory && (
      <button className="library-entry-button" onClick={onOpen}>
        Open
      </button>
    )}
    {!isDirectory && (
      <button className="library-entry-button" onClick={onDelete}>
        Delete
      </button>
    )}
  </div>
);


  // Render sublibraries and files
  const renderLibraryEntries = () => {
    const directoryEntries = libraryFiles
      .filter((item) => item.type === 'directory')
      .map((directory) => (
        <LibraryEntry
          key={directory.name}
          name={directory.name}
          onOpen={() => navigateToDirectory(directory.name)}
          isDirectory
        />
      ));

    const fileEntries = libraryFiles
      .filter((item) => item.type === 'file')
      .map((file) => (
        <LibraryEntry
          key={file.name}
          name={file.name}
          onDelete={() => handleDelete(file.name)}
        />
      ));

    return [...directoryEntries, ...fileEntries];
  };


  // Sidebar JSX for libraries
  const LibrarySidebar = () => (
    <aside className="library-sidebar">
      <h2>Libraries</h2>
      <nav className="library-navigation">
        {libraries.map((library) => (
          <button key={library} onClick={() => handleLibrarySelect(library)} className="library-button">
            {library}
          </button>
        ))}
      </nav>
    </aside>
  );

  // Content Area JSX for selected library details
  const LibraryContent = () => (
    <section className="library-content">
      {selectedLibrary && (
        <>
          <div className="library-path-toolbar">
            {currentPath && <button onClick={navigateBack} className="back-button">Back</button>}
            <h3>{getCurrentLocationDisplay()}</h3>
          </div>
          <div className="library-sublibraries">
            <h4>Sublibraries</h4>
            {renderSublibraries()}
          </div>
          <div className="library-files">
            <h4>Files</h4>
            {renderLibraryFiles()}
          </div>
          <FileUploadSection />
          <LibraryActions />
        </>
      )}
    </section>
  );

  // JSX for file upload section
  const FileUploadSection = () => (
    <div className="file-upload" onDragOver={handleDragOver} onDrop={handleDrop}>
      <p>Drop files here or click 'Choose Files' to upload</p>
      <div className="file-names">
      {Array.from(selectedFiles).map((file, index) => (
          <div key={index}>{file.name}</div>
        ))}
      </div>
    </div>
  );

  // JSX for library actions like file upload and embeddings
  const LibraryActions = () => (
    <div className="library-actions">
      <input type="file" multiple onChange={handleFileSelect} className="file-input" />
      <button onClick={() => document.querySelector('.library-actions .file-input').click()}>
        Choose Files
      </button>
      <button onClick={handleUpload}>Upload Files to Library</button>
      {renderCreateEmbeddingsButton()}
      <EmbeddingResults />
    </div>
  );

  // JSX for displaying embedding results in a table
  const EmbeddingResults = () => (
    embeddingResults && (
      <div className="embedding-results-table">
        <table>
          <thead>
            <tr>
              <th>Operation</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Creating library</td>
              <td>{embeddingResults.library}</td>
            </tr>
            <tr>
              <td>Parsing and Text Indexing Files</td>
              <td>
                Docs: {embeddingResults.docs_added}, Blocks: {embeddingResults.blocks_added}, Images: {embeddingResults.images_added}, Pages: {embeddingResults.pages_added}, Tables: {embeddingResults.tables_added}
              </td>
            </tr>
            <tr>
              <td>Generating Embeddings</td>
              <td>{embeddingResults.embedding_status}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
  
  return (
    <div className="library-management-container">
      <LibrarySidebar />
      <section className="library-content">
        {/* Path navigation and library entries */}
        <div className="library-path-toolbar">
          {currentPath && <button onClick={navigateBack} className="back-button">Back</button>}
          <h3>{getCurrentLocationDisplay()}</h3>
        </div>
        <div className="library-entries">
          {renderLibraryEntries()}
        </div>
        <FileUploadSection />
        <LibraryActions />
      </section>

      {/* Loading Overlay */}
      {isProcessing && (
        <div className="loading-overlay">
          <div className="loading-spinner-container">
            <div className="loading-spinner"></div>
          </div>
          <p className="loading-message">Please wait, processing your request...</p>
        </div>
      )}
    </div>
  );
};

export default LibraryManagement;
// AdminPage.js
import React, { useState } from 'react';
import LibraryManagement from './LibraryManagement';
import FileManagement from './FileManagement';
import BotManagement from './BotManagement';
import BotLibraryMapping from './BotLibraryMapping';
import ModelManagement from './ModelManagement'; // Import the new component
import DropboxManagement from './DropboxManagement'; // Import DropboxManagement component
import UserGroupManagement from './UserGroupManagement'; // Import UserGroupManagement component
import TenantManagement from './TenantManagement'; // Import TenantManagement component
import LibraryPermissionManagement from './LibraryPermissionManagement'; // Import LibraryPermissionManagement component
import './AdminPage.css';

function AdminPage({ onBackClick, selectedFile, setSelectedFile }) {
  const [currentView, setCurrentView] = useState('LibraryManagement');

  return (
    <div className="admin-container">
      <div className="admin-nav-buttons">
        <button onClick={() => setCurrentView('LibraryManagement')}>Library Management</button>
        <button onClick={() => setCurrentView('FileManagement')}>File Management</button>
        <button onClick={() => setCurrentView('BotManagement')}>Bot Management</button>
        <button onClick={() => setCurrentView('BotLibraryMapping')}>Bot-Library Mapping</button>
        <button onClick={() => setCurrentView('ModelManagement')}>Model Management</button> 
        <button onClick={() => setCurrentView('DropboxManagement')}>Dropbox Management</button> 
        <button onClick={() => setCurrentView('UserGroupManagement')}>User Group Management</button> 
        <button onClick={() => setCurrentView('TenantManagement')}>Tenant Management</button> 
        <button onClick={() => setCurrentView('LibraryPermissionManagement')}>Library Permission Management</button> 
      </div>

      {currentView === 'LibraryManagement' && <LibraryManagement />}
      {currentView === 'FileManagement' && <FileManagement />}
      {currentView === 'BotManagement' && <BotManagement />}
      {currentView === 'BotLibraryMapping' && <BotLibraryMapping />}
      {currentView === 'ModelManagement' && <ModelManagement />} 
      {currentView === 'DropboxManagement' && <DropboxManagement />} 
      {currentView === 'UserGroupManagement' && <UserGroupManagement />} 
      {currentView === 'TenantManagement' && <TenantManagement />} 
      {currentView === 'LibraryPermissionManagement' && <LibraryPermissionManagement />} 
    </div>
  );
}

export default AdminPage;

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../auth/AuthContext';
import './RagBotQueryChat.css';

const RagBotQueryChat = ({ selectedBot, botLibraries, botModels = [] }) => {
  const { token } = useAuth();
  const [modelName, setModelName] = useState('');
  const [resultCount, setResultCount] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [promptText, setPromptText] = useState('');
  const [messages, setMessages] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const fetchWithToken = (url, options = {}) => {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };
    return fetch(url, options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim() || !promptText.trim()) return;

    const userMessage = { role: 'user', content: `${searchQuery} - ${promptText}` };
    setMessages((messages) => [...messages, userMessage]);

    try {
      const requestBody = {
        library_names: botLibraries,
        model_name: modelName,
        search_query: searchQuery,
        prompt_text: promptText,
        result_count: resultCount,
      };

      const response = await fetchWithToken(`${API_URL}/api/chat/ragBotQuery`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      const responseData = await response.json();
      const aiMessage = { role: 'ai', content: formatResponse(responseData) };
      setMessages((messages) => [...messages, aiMessage]);
    } catch (error) {
      console.error('Fetch error:', error);
    }

    setSearchQuery('');
    setPromptText('');
  };

  // This method formats the response to include both LLM responses and query results,
  // closely mirroring the functionality seen in the older version of the code.
  const formatResponse = (responseData) => {
    let formattedResponse = '## LLM Responses\n';
    responseData.llm_responses.forEach((response, index) => {
      formattedResponse += `### Response ${index + 1}\n${response}\n\n`;
    });

    // Format Query Results
    if (responseData.query_results && responseData.query_results.length > 0) {
      formattedResponse += `### Query Results\n\n`;
      responseData.query_results.forEach((result, index) => {
        const formattedLibraryName = result.library_name.replace(/_/g, ' ').replace(/-/g, ' ');
        const encodedFileName = encodeURIComponent(getFileName(result.file_source));
        formattedResponse += `#### Result ${index + 1}\n`;
        formattedResponse += `**Distance:** ${result.distance}\n`;
        formattedResponse += `**Library name:** ${formattedLibraryName}\n`;
        formattedResponse += `**File Source:** [${getFileName(result.file_source)}](${result.file_url.replace(encodeURIComponent(getFileName(result.file_source)), encodedFileName)})\n`;
        formattedResponse += `**Page Number:** ${result.page_num}\n`;
        formattedResponse += `**Text:** ${result.text}\n\n`;
        formattedResponse += `---\n\n`;
      });
    }

    // Add report file link if available
    if (responseData.report_file) {
      formattedResponse += `[Download Report](${responseData.report_file})\n`;
    }

    return formattedResponse;
  };

  // Helper function to extract the file name from a file path
  const getFileName = (filePath) => {
    return filePath.split('\\').pop().split('/').pop();
  };

  useEffect(() => {
    if (botModels.length > 0) {
      setModelName(botModels[0]);
    }
  }, [botModels]);

  return (
    <>
      <h1>{selectedBot} RAG Query Chat</h1>
      <form onSubmit={handleSubmit}>
        <div className="select-wrapper">
          <select value={modelName} onChange={(e) => setModelName(e.target.value)} className="styled-select">
            <option value="">Select a model</option>
            {botModels.map((model, index) => (
              <option key={index} value={model}>{model}</option>
            ))}
          </select>
        </div>
        <div className="select-wrapper">
          <select value={resultCount} onChange={(e) => setResultCount(e.target.value)} className="styled-select">
            {[5, 10, 15, 20, 25, 30].map(count => (
              <option key={count} value={count}>{count} Results</option>
            ))}
          </select>
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Enter your search query..."
        />
        <input
          type="text"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          placeholder="Enter your prompt text..."
        />
        <button type="submit">Send Query</button>
      </form>
      <div className="ragBotQueryChat-window">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            <ReactMarkdown>{msg.content}</ReactMarkdown>
          </div>
        ))}
      </div>
    </>
  );
};

export default RagBotQueryChat;

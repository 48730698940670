import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import './LibraryPermissionManagement.css'; // Make sure this CSS file matches the LibraryManagement styles

const LibraryPermissionsManagement = () => {
  const { token } = useAuth();
  const [libraries, setLibraries] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedLibrary, setSelectedLibrary] = useState(null);
  const [userPermissions, setUserPermissions] = useState({});
  const [groupPermissions, setGroupPermissions] = useState({});
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  useEffect(() => {
    fetchWithToken(`${API_URL}/api/config/library_permission_management/libraries`)
      .then(response => response.json())
      .then(data => setLibraries(data))
      .catch(error => console.error('Error fetching libraries:', error));

    fetchWithToken(`${API_URL}/api/auth_config/user_config/users`)
      .then(response => response.json())
      .then(data => setUsers(data))
      .catch(error => console.error('Error fetching users:', error));

    fetchWithToken(`${API_URL}/api/auth_config/group_config/groups`)
      .then(response => response.json())
      .then(data => setGroups(data))
      .catch(error => console.error('Error fetching groups:', error));
  }, [API_URL, token]);

  const fetchWithToken = (url, options = {}) => {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`
    };
    return fetch(url, options);
  };

  const handleSelectLibrary = (library) => {
    setSelectedLibrary(library);
    // Fetch current permissions
    fetchWithToken(`${API_URL}/api/config/library_permission_management/libraries/${library._id}/permissions`)
      .then(response => response.json())
      .then(data => {
        const userPerms = users.reduce((acc, user) => ({
          ...acc,
          [user._id]: data.userIds.includes(user._id)
        }), {});
        setUserPermissions(userPerms);

        const groupPerms = groups.reduce((acc, group) => ({
          ...acc,
          [group._id]: data.groupIds.includes(group._id)
        }), {});
        setGroupPermissions(groupPerms);
      })
      .catch(error => console.error('Error fetching library permissions:', error));
  };

  const handleUpdatePermissions = () => {
    const userIds = Object.keys(userPermissions).filter(id => userPermissions[id]);
    const groupIds = Object.keys(groupPermissions).filter(id => groupPermissions[id]);

    fetchWithToken(`${API_URL}/api/config/library_permission_management/libraries/${selectedLibrary._id}/permissions`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userIds, groupIds })
    })
      .then(response => response.json())
      .then(() => console.log('Permissions updated successfully'))
      .catch(error => console.error('Error updating permissions:', error));
  };

  const handlePermissionChange = (type, id) => {
    if (type === 'user') {
      setUserPermissions(prev => ({ ...prev, [id]: !prev[id] }));
    } else {
      setGroupPermissions(prev => ({ ...prev, [id]: !prev[id] }));
    }
  };

  // Sidebar for library selection
  const LibrarySidebar = () => (
    <aside className="library-sidebar">
      <h2>Libraries</h2>
      <div className="library-navigation">
        {libraries.map(lib => (
          <button key={lib._id} className="library-button" onClick={() => handleSelectLibrary(lib)}>
            {lib.library_name}
          </button>
        ))}
      </div>
    </aside>
  );

  // Main content area for permissions management
  const PermissionsContent = () => (
    <section className="library-content">
      {selectedLibrary && (
        <>
          <div className="selected-library-header">
            <h3>{selectedLibrary.library_name}</h3>
          </div>
          <div className="permissions-section">
            <h3>Users</h3>
            <div className="permissions-list">
              {users.map(user => (
                <label key={user._id} className="permissions-label">
                  <input
                    type="checkbox"
                    checked={userPermissions[user._id] || false}
                    onChange={() => handlePermissionChange('user', user._id)}
                  />
                  {user.username}
                </label>
              ))}
            </div>
          </div>

          <div className="permissions-section">
            <h3>Groups</h3>
            <div className="permissions-list">
              {groups.map(group => (
                <label key={group._id} className="permissions-label">
                  <input
                    type="checkbox"
                    checked={groupPermissions[group._id] || false}
                    onChange={() => handlePermissionChange('group', group._id)}
                  />
                  {group.groupName}
                </label>
              ))}
            </div>
          </div>

          <button className="submit-btn" onClick={handleUpdatePermissions}>Update Permissions</button>
        </>
      )}
    </section>
  );

  return (
    <div className="library-permissions-management">
      <LibrarySidebar />
      <PermissionsContent />
    </div>
  );
};

export default LibraryPermissionsManagement;

import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../auth/AuthContext';
import './TenantManagement.css';
import TenantConfig from './TenantConfig';
import TenantGroups from './TenantGroups';
import TenantUsers from './TenantUsers';

const TenantManagement = () => {
    const { token } = useAuth();
    const [tenants, setTenants] = useState([]);
    const [newTenantName, setNewTenantName] = useState('');
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [selectedSection, setSelectedSection] = useState('config');
    const [tenantConfig, setTenantConfig] = useState({});
    const [groups, setGroups] = useState([]); // Add state for groups
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';



    const fetchTenants = useCallback(async () => {
        const response = await fetchWithToken(`${API_URL}/api/auth_config/tenant_config/tenants`);
        if (!response.ok) {
            console.error('Failed to fetch tenants:', response.statusText);
            return;
        }
        const data = await response.json();
        setTenants(data);
    }, [token, API_URL]);

    const createTenant = useCallback(async () => {
        const response = await fetchWithToken(`${API_URL}/api/auth_config/tenant_config/tenants/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tenantName: newTenantName }),
        });
        if (!response.ok) {
            console.error('Failed to create tenant:', response.statusText);
            return;
        }
        setNewTenantName('');
        fetchTenants();
    }, [newTenantName, token, API_URL, fetchTenants]);

    const fetchTenantConfig = useCallback(async (tenantId) => {
        const response = await fetchWithToken(`${API_URL}/api/auth_config/tenant_config/tenants/${tenantId}/config`);
        if (!response.ok) {
            console.error('Failed to fetch tenant config:', response.statusText);
            return;
        }
        const data = await response.json();
        setTenantConfig(data);
    }, [token, API_URL]);

    const updateTenantConfig = useCallback(async (updatedConfig) => {
        const response = await fetchWithToken(`${API_URL}/api/auth_config/tenant_config/tenants/${selectedTenant}/config`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedConfig),
        });
        if (!response.ok) {
            console.error('Failed to update tenant config:', response.statusText);
            return;
        }
        fetchTenantConfig(selectedTenant);
    }, [selectedTenant, token, API_URL, fetchTenantConfig]);

    const onTenantClick = useCallback((tenantId) => {
        setSelectedTenant(tenantId);
        setSelectedSection('config'); // Default to TenantConfig on tenant selection
        fetchTenantConfig(tenantId);
    }, [fetchTenantConfig]);

    const onSectionClick = useCallback((section) => {
        setSelectedSection(section);
    }, []);

    useEffect(() => {
        fetchTenants();
    }, [fetchTenants]);

    const fetchWithToken = async (url, options = {}) => {
        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            console.error('Fetch with token error:', error);
            throw new Error('Failed to execute fetch with token.');
        }
    };



    return (
        <div className="tenant-management-container">
            <Sidebar
                tenants={tenants}
                newTenantName={newTenantName}
                setNewTenantName={setNewTenantName}
                createTenant={createTenant}
                onTenantClick={onTenantClick}
                selectedTenant={selectedTenant}
                selectedSection={selectedSection}
                onSectionClick={onSectionClick}
                groups={selectedSection === 'groups' ? groups : []} // Pass groups to Sidebar when 'groups' section is selected
            />
            {selectedTenant && selectedSection === 'config' && <TenantConfig tenantConfig={tenantConfig} updateTenantConfig={updateTenantConfig} />}
            {selectedTenant && selectedSection === 'groups' && <TenantGroups selectedTenant={selectedTenant} />}
            {selectedTenant && selectedSection === 'users' && <TenantUsers selectedTenant={selectedTenant} />} {/* Add this line to render TenantUser when 'users' section is selected */}
        </div>
    );
};

const Sidebar = ({
    tenants,
    newTenantName,
    setNewTenantName,
    createTenant,
    onTenantClick,
    selectedTenant,
    selectedSection,
    onSectionClick,
    groups // Accept groups as a prop
}) => (
    <aside className="sidebar">
        <div className="tenant-creation">
            <input
                type="text"
                placeholder="New Tenant Name"
                value={newTenantName}
                onChange={(e) => setNewTenantName(e.target.value)}
            />
            <button onClick={createTenant}>Create Tenant</button>
        </div>
        <div className="tenant-list">
            {tenants.map(tenant => (
                <div key={tenant._id}>
                    <div
                        className={`tenant-item ${selectedTenant === tenant._id ? 'selected' : ''}`}
                        onClick={() => onTenantClick(tenant._id)}
                    >
                        {tenant.tenantName}
                    </div>
                    {selectedTenant === tenant._id && (
                        <div className="tenant-options">
                            <div
                                className={`option-item ${selectedSection === 'config' ? 'selected' : ''}`}
                                onClick={() => onSectionClick('config')}
                            >
                                Configuration
                            </div>
                            <div
                                className={`option-item ${selectedSection === 'users' ? 'selected' : ''}`}
                                onClick={() => onSectionClick('users')}
                            >
                                Users
                            </div>
                            <div
                                className={`option-item ${selectedSection === 'groups' ? 'selected' : ''}`}
                                onClick={() => onSectionClick('groups')}
                            >
                                Groups
                                {selectedSection === 'groups' && (
                                    <ul className="nested-group-list">
                                        {groups.map(group => (
                                            <li key={group._id} className="nested-group-item">
                                                {group.groupName}
                                                {/* You can add more interactive elements like edit or delete buttons here */}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {/* Add more sections as needed */}
                        </div>
                    )}
                </div>
            ))}
        </div>
    </aside>
);



export default TenantManagement;

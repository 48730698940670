import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext'; // Import useAuth hook
import './FileManagement.css';

const FileManagement = () => {
    const { token } = useAuth(); // Access token from AuthContext
    const [availableFiles, setAvailableFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    // A helper function to include the token in API requests
    const fetchWithToken = (url, options = {}) => {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}` // Include token in headers
        };
        return fetch(url, options);
    };

    // Function to fetch and list files
    const fetchFiles = () => {
        fetchWithToken(`${API_URL}/api/list-files`)
            .then(response => response.json())
            .then(data => setAvailableFiles(data.files))
            .catch(error => console.error('Error fetching files:', error));
    };

    // Fetch files on component mount
    useEffect(() => {
        fetchFiles();
    }, [API_URL, token]); // Include token as a dependency

    // Handle file deletion
    const handleDelete = (fileName) => {
        fetchWithToken(`${API_URL}/api/delete-file`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fileName })
        })
        .then(response => {
            if (!response.ok) throw new Error('Failed to delete file');
            return response.json();
        })
        .then(() => {
            // Update the file list
            setAvailableFiles(availableFiles.filter(file => file !== fileName));
        })
        .catch(error => console.error('Error deleting file:', error));
    };

    // Handle file upload
    const handleUpload = () => {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('files', selectedFiles[i]);
        }

        fetchWithToken(`${API_URL}/api/upload-file`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}` }, // For multipart/form-data, omit 'Content-Type' header
            body: formData
        })
        .then(response => {
            if (!response.ok) throw new Error('Failed to upload file');
            return response.json();
        })
        .then(() => {
            fetchFiles(); // Refresh file list after upload
        })
        .catch(error => console.error('Error uploading file:', error));
    };


    const handleFiles = (files) => {
        setSelectedFiles(files);
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Prevent default behavior (prevent file from being opened)
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFiles(e.dataTransfer.files); // Process the dropped files
    };

    const handleFileSelect = (event) => {
        handleFiles(event.target.files);
    };

  // Function to render file names
  const renderFileNames = () => {
    return selectedFiles.length > 0 && (
      <div className="file-names">
        {Array.from(selectedFiles).map((file, index) => (
          <div key={index}>{file.name}</div>
        ))}
      </div>
    );
  };


    return (
        <div className="file-management">
          <div className="file-list">
            {availableFiles.map(file => (
              <div key={file} className="file-entry">
                {file} <button onClick={() => handleDelete(file)} className="delete-btn">Delete</button>
              </div>
            ))}
          </div>
          <div className="file-upload" onDragOver={handleDragOver} onDrop={handleDrop}>
        <p>Drop files here</p>
        {renderFileNames()} {/* Call to render file names */}
      </div>
      <div className="file-actions">
        <input type="file" multiple onChange={handleFileSelect} style={{ display: 'none' }} /> {/* Hide the default file input */}
        <button onClick={() => document.querySelector('.file-actions input[type="file"]').click()}>
          Choose Files
        </button> {/* Programmatically click the hidden file input */}
        <button onClick={handleUpload}>Upload</button>
      </div>
    </div>
  );
};
    
    export default FileManagement;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';
import './BotLibraryMapping.css';

const BotLibraryMapping = () => {
  const { token } = useAuth();
  const [mappings, setMappings] = useState([]);
  const [bots, setBots] = useState([]);
  const [libraries, setLibraries] = useState([]);
  const [models, setModels] = useState([]); // Added state for models
  const [selectedBot, setSelectedBot] = useState('');
  const [selectedLibraries, setSelectedLibraries] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]); // Added state for selected models
  const [filter, setFilter] = useState('all'); // 'all', 'library', or 'model'
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const fetchWithToken = (url, options = {}) => {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };
    return fetch(url, options);
  };

  useEffect(() => {
    fetchWithToken(`${API_URL}/api/config/bot-collections`)
      .then(response => response.json())
      .then(setBots)
      .catch(error => console.error('Error fetching bots:', error));

    fetchWithToken(`${API_URL}/api/libraries/load-libraries`)
      .then(response => response.json())
      .then(setLibraries)
      .catch(error => console.error('Error fetching libraries:', error));

    fetchWithToken(`${API_URL}/api/models/list_models`) // Fetch models
      .then(response => response.json())
      .then(setModels)
      .catch(error => console.error('Error fetching models:', error));

    fetchWithToken(`${API_URL}/api/config/bot-mappings`)
      .then(response => response.json())
      .then(data => {
        const processedMappings = data.map(mapping => ({
          ...mapping,
          mappingName: mapping.mappingType === 'library' ? mapping.mappingName : mapping.mappingName, // Directly use mappingName
          type: mapping.mappingType
        }));
        setMappings(processedMappings);
      })
      .catch(error => console.error('Error fetching mappings:', error));
  }, [API_URL, token, selectedBot]); // Add selectedBot to useEffect dependencies if needed

  useEffect(() => {
    if (selectedBot) {
      const botMappings = mappings.filter(mapping => mapping.botId === selectedBot);
      const libraryNames = botMappings.filter(mapping => mapping.mappingType === 'library').map(mapping => mapping.mappingName);
      const modelNames = botMappings.filter(mapping => mapping.mappingType === 'model').map(mapping => mapping.mappingName);

      setSelectedLibraries(libraryNames);
      setSelectedModels(modelNames);
    } else {
      setSelectedLibraries([]);
      setSelectedModels([]);
    }
  }, [selectedBot, mappings]);


  const handleToggleLibrary = (libraryName) => {
    const updatedLibraries = selectedLibraries.includes(libraryName)
      ? selectedLibraries.filter(lib => lib !== libraryName)
      : [...selectedLibraries, libraryName];
    setSelectedLibraries(updatedLibraries);
  };

  const handleToggleModel = (modelName) => {
    // Determine if the model is currently selected based on model_name
    const modelIsSelected = selectedModels.includes(modelName);
    if (modelIsSelected) {
      setSelectedModels(selectedModels.filter(model => model !== modelName));
    } else {
      setSelectedModels([...selectedModels, modelName]);
    }
  };
  

  const handleSaveMappings = async () => {
    const botName = bots.find(bot => bot._id === selectedBot)?.name;

    // Prepare the request body to include arrays of libraryNames and modelNames
    const requestBody = {
      botId: selectedBot,
      libraryNames: selectedLibraries,
      modelNames: selectedModels,
    };

    try {
      const response = await fetchWithToken(`${API_URL}/api/config/bot-mappings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to save mappings");
      }

      // Assuming successful operation, update the local state to reflect the new mappings
      // Fetch the updated mappings to refresh the UI
      await fetchMappings();
    } catch (error) {
      console.error('Failed to save mappings:', error);
    }
  };

  const fetchMappings = async () => {
    try {
      const response = await fetchWithToken(`${API_URL}/api/config/bot-mappings`);
      if (!response.ok) {
        throw new Error("Failed to fetch mappings");
      }
      const mappingsData = await response.json();
      setMappings(mappingsData.map(mapping => ({
        ...mapping,
        type: mapping.libraryName ? 'library' : 'model',
      })));
    } catch (error) {
      console.error('Failed to fetch mappings:', error);
    }
  };


  const handleDeleteMapping = async (botId, mappingName, type) => {
    await fetchWithToken(`${API_URL}/api/config/bot-mappings/${botId}/${mappingName}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // Refresh mappings after deletion
    const updatedMappingsResponse = await fetchWithToken(`${API_URL}/api/config/bot-mappings`);
    const updatedMappingsData = await updatedMappingsResponse.json();
    setMappings(updatedMappingsData.map(mapping => ({
      ...mapping,
      type: mapping.libraryName ? 'library' : 'model',
    })));
  };

  const handleSelectBot = (botId) => {
    setSelectedBot(botId);
  };


  return (
    <div className="bot-library-mapping-container">
      <div className="library-sidebar">
        <h2>Select a Bot</h2>
        <div className="bot-selection">
          {bots.map(bot => (
            <button key={bot._id} className={`bot-button ${selectedBot === bot._id ? 'selected' : ''}`} onClick={() => handleSelectBot(bot._id)}>
              {bot.name}
            </button>
          ))}
        </div>
      </div>

      <div className="library-content">
        <h2>Bot Mappings</h2>

        <div className="mapping-controls">
          {/* Library Mappings */}
          <div className="library-table">
            <div className="library-table-header">
              <div>Library Name</div>
              <div>Include</div>
            </div>
            {libraries.map(library => (
              <div key={library._id} className="library-table-row"> {/* Ensure unique key, using _id if available */}
                <div className="library-name">{library.name}</div>
                <div className="library-checkbox">
                  <input
                    id={`library-${library._id}`} // Use _id for uniqueness
                    type="checkbox"
                    className="styled-checkbox"
                    checked={selectedLibraries.includes(library.name)}
                    onChange={() => handleToggleLibrary(library.name)}
                  />
                  <label htmlFor={`library-${library._id}`}></label>
                </div>
              </div>
            ))}
          </div>

          {/* Model Mappings */}
          <div className="model-table" style={{ marginTop: '20px' }}>
            <div className="model-table-header">
              <div>Model Name</div>
              <div>Include</div>
            </div>
            {models.map(model => (
  <div key={model._id} className="library-table-row">
    <div className="library-name">{model.display_name}</div>
    <div className="library-checkbox">
      <input
        id={`model-${model._id}`}
        type="checkbox"
        className="styled-checkbox"
        // Check if model's model_name is in selectedModels for checked state
        checked={selectedModels.includes(model.model_name)}
        // Pass model.model_name to the toggle handler
        onChange={() => handleToggleModel(model.model_name)}
      />
      <label htmlFor={`model-${model._id}`}></label>
    </div>
  </div>
))}

          </div>

          <button onClick={handleSaveMappings} style={{ marginTop: '20px' }}>Save Mappings</button>
        </div>

        {/* Combined Library and Model Mappings Display */}
        {/* Step 2: Added Filter Selection UI */}
        <div className="filter-selection">
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All Mappings</option>
            <option value="library">Library Mappings</option>
            <option value="model">Model Mappings</option>
          </select>
        </div>
        <table className="mapping-table" style={{ marginTop: '20px' }}>
          <thead>
            <tr>
              <th>Bot Name</th>
              <th>Mapping Name</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* Step 4: Corrected display logic for combined mappings */}
            {mappings
              .filter(mapping => filter === 'all' || mapping.type === filter)
              .map(mapping => (
                <tr key={mapping._id}>
                  <td>{mapping.botName}</td>
                  <td>{mapping.mappingName}</td>
                  <td>{mapping.type.charAt(0).toUpperCase() + mapping.type.slice(1)}</td>
                  <td>
                    <button className="delete-btn" onClick={() => handleDeleteMapping(mapping.botId, mapping.mappingName, mapping.type)}>
                      Remove Mapping
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BotLibraryMapping;
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import './LoginAndRegister.css';

function LoginAndRegister({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [registering, setRegistering] = useState(false);
    const [isCheckingSession, setIsCheckingSession] = useState(true);
    const [sessionCheckFailed, setSessionCheckFailed] = useState(false);
    const { setAuthInfo, isAuthenticated } = useAuth();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    useEffect(() => {
        if (!isAuthenticated) {
            checkSession();
        }
    }, [isAuthenticated]);

    const checkSession = async () => {
        try {
            const response = await fetch(`${API_URL}/api/session/validate`, {
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setAuthInfo({
                    token: data.token,
                    user: {
                        userId: data.user.userId,
                        username: data.user.username,
                        role: data.user.role,
                    },
                });
                onLoginSuccess(data.token);
            } else {
                setSessionCheckFailed(true);
            }
        } catch (error) {
            console.error('Session check error:', error);
            setSessionCheckFailed(true);
        } finally {
            setIsCheckingSession(false);
        }
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/api/user_authentication/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();
            if (response.ok) {
                setAuthInfo({
                    token: data.token,
                    user: {
                        userId: data.userId,
                        username,
                        role: data.role,
                    },
                });
                onLoginSuccess(data.token);
            } else {
                alert(data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred while logging in');
        }
    };

    const handleRegisterSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch(`${API_URL}/api/user_authentication/register`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ username, password })
          });

          if (response.ok) {
              alert('Registration successful. Please log in.');
              setRegistering(false); // Switch back to login form after successful registration
          } else {
              const data = await response.json();
              alert(data.message || 'Registration failed');
          }
      } catch (error) {
          console.error('Registration error:', error);
          alert('An error occurred during registration');
      }
  };


    if (isCheckingSession) {
        return <div>Loading...</div>; // Or any loading indicator you prefer
    }

    if (sessionCheckFailed && !isAuthenticated) {
        return (
            <div className="login-container">
          {registering ? (
            <form className="login-form" onSubmit={handleRegisterSubmit}>
              <h2 className="login-title">Register</h2>
              <input
                className="login-input"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                aria-label="Username"
              />
              <input
                className="login-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                aria-label="Password"
              />
              <button className="login-button" type="submit">Register</button>
              <button className="login-button" type="button" onClick={() => setRegistering(false)}>Back to Login</button>
            </form>
          ) : (
            <form className="login-form" onSubmit={handleLoginSubmit}>
              <h2 className="login-title">Login</h2>
              <input
                className="login-input"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                aria-label="Username"
              />
              <input
                className="login-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                aria-label="Password"
              />
              <button className="login-button" type="submit">Login</button>
              <button className="login-button" type="button" onClick={() => setRegistering(true)}>Register</button>
            </form>
          )}
            </div>
        );
    }

    // If session check did not fail or user is already authenticated, you might want to redirect or show a different component
    return null; // Or redirect user to the dashboard/home page
}

export default LoginAndRegister;